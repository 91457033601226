.user-list {
  font-size: 1.4rem;

  figure {
    text-align: center;

    a:not(.btn) {
      padding-bottom: 0.8rem;

      figcaption {
        display: inline;
        position: relative;
        font-size: 2rem;

        p {
          font-family: 'lexicon_no1', Times, serif;
          margin-bottom: 0;
        }
      }

      &:hover,
      .active {
        &::after {
          display: none;
        }

        figcaption {
          &::after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            bottom: -0.4rem;
            width: 100%;
            height: 0.1rem;
            border-bottom: 1.4em solid $color__secondary--default;
          }
        }
      }
    }
  }
}
