.teaser_short {
  a {
    text-decoration: none;
    //height: 100%;
    display: inline-block;

    &:hover {
      text-decoration: none;

      &::before {
        display: none;
        height: 0;
      }
    }

    &:active {
      text-decoration: none;
    }
  }

  figure {
    height: 100%;
    position: relative;

    &::before {
      content: ' ';
      display: inline-block;
      padding-top: auto;
    }

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 100%;
      object-fit: contain;
      // Polyfill-Notation for object-fit-images (IE11)
      font-family: 'object-fit: contain;';
    }
  }

  header {
    display: inline-block;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: Akkurat, Helvetica, Arial, sans-serif;
      font-size: 1.8rem;
      line-height: 1.4;
      margin: 0;
      position: relative;
      display: inline-block;

      b {
        font-family: inherit;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        right: -0.3rem;
        bottom: -0.2em;
        left: -0.3rem;
        background: $color__secondary--default;
        width: calc(100% + 0.6rem);
        height: 1em;
        display: none;
      }
    }

    p {
      font-family: Akkurat, Helvetica, Arial, sans-serif;
      margin-top: 0;
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 1.4;
      margin-bottom: 0;
      text-rendering: auto;
      display: inline-block;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        right: -0.3rem;
        bottom: -0.2em;
        left: -0.3rem;
        background: $color__secondary--default;
        width: calc(100% + 0.6rem);
        height: 1em;
        display: none;
      }
    }
  }

  section {
    margin: 0 0 1rem;
    font-family: Akkurat, Helvetica, Arial, sans-serif;
    font-weight: 400;

    p {
      font-family: 'lexicon_no1', Times, serif;
      font-size: 1.2rem;
      text-align: left;
      line-height: 1.6;
      display: inline-block;
      position: relative;
      margin: 0;

      b {
        font-weight: bold;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        right: -0.3rem;
        bottom: -0.2em;
        left: -0.3rem;
        background: $color__secondary--default;
        width: calc(100% + 0.6rem);
        height: 1em;
        display: none;
      }
    }
  }

  &.teaser__item-img-cover {
    height: 100%;

    img {
      object-fit: cover;
      // Polyfill-Notation for object-fit-images (IE11)
      font-family: 'object-fit: cover;';
    }
  }

  footer {
    .teaser__date {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-family: Akkurat, Helvetica, Arial, sans-serif;
    }
  }

  a {
    &:hover,
    &:active,
    &.active {
      header {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          &::before {
            display: inline-block;
          }
        }

        // p {
        //   &::before {
        //     display: inline-block;
        //   }
        // }
      }

      section {
        p {
          &::before {
            display: inline-block;
          }
        }
      }

      footer {
        svg.icon {
          color: $color__secondary--default;
        }
      }
    }
  }

  &.xl {
    .teaser__content {
      .teaser__title {
        h3 {
          font-size: 2.2rem;
        }
      }

      .teaser__subtitle {
        p {
          font-size: 1.5rem;
        }
      }

      .teaser__text {
        p {
          font-size: 1.8rem;
        }
      }
    }
  }

  &.xxl {
    .teaser__content {
      .teaser__title {
        h3 {
          font-size: 2.6rem;
        }
      }

      .teaser__subtitle {
        p {
          font-size: 1.9rem;
        }
      }

      .teaser__text {
        p {
          font-size: 2.2rem;
        }
      }
    }
  }
}

@media screen and (min-width: $mq-s) {
  .teaser {
    figure {
      &::before {
        padding-top: auto;
      }
    }
  }
}

.teaser_short {
  a {
    text-decoration: none;
    //height: 100%;
    display: inline-block;

    &:hover {
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
    }
  }

  figure {
    height: 100%;
    position: relative;

    &::before {
      content: ' ';
      display: inline-block;
      padding-top: auto;
    }

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 100%;
      object-fit: contain;
      // Polyfill-Notation for object-fit-images (IE11)
      font-family: 'object-fit: contain;';
    }
  }

  &.teaser__item-img-cover {
    height: 100%;

    img {
      object-fit: cover;
      // Polyfill-Notation for object-fit-images (IE11)
      font-family: 'object-fit: cover;';
    }
  }

  .teaser__content {
    margin: 0;
    font-family: Akkurat, Helvetica, Arial, sans-serif;
    font-weight: 400;

    header {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: Akkurat, Helvetica, Arial, sans-serif;
        font-size: 1.8rem;
        line-height: 1.3em;
        margin: 0;
        text-align: center;

        b {
          font-family: inherit;
        }
      }

      p {
        font-family: 'lexicon_no1', Times, serif;
        margin-top: 0;
        font-size: 1.1rem;
        font-weight: 400;
        line-height: 1.3em;
        margin-bottom: 0;
        text-rendering: auto;
        text-align: center;
      }
    }

    .teaser__text {
      a {
        &::before {
          display: none;
        }
      }

      p {
        font-family: 'lexicon_no1', Times, serif;
        font-size: 1.4rem;
        line-height: 1.4em;
        text-align: center;
      }
    }

    .teaser__date {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-family: Akkurat, Helvetica, Arial, sans-serif;
    }

    a.teaser__link {
      color: inherit;
      display: inline-block;

      &:hover {
        text-decoration: underline;

      }

      &:active {
        text-decoration: underline;

      }
    }

    a.btn {
      margin: 0 auto;
    }
  }

  &.xl {
    .teaser__content {
      .teaser__title {
        h3 {
          font-size: 2.2rem;
        }
      }

      .teaser__subtitle {
        p {
          font-size: 1.5rem;
        }
      }

      .teaser__text {
        p {
          font-size: 1.8rem;
        }
      }
    }
  }

  &.xxl {
    .teaser__content {
      .teaser__title {
        h3 {
          font-size: 2.6rem;
        }
      }

      .teaser__subtitle {
        p {
          font-size: 1.9rem;
        }
      }

      .teaser__text {
        p {
          font-size: 2.2rem;
        }
      }
    }
  }
}

@media screen and (min-width: $mq-m) {
  .teaser_short {
    figure {
      &::before {
        padding-top: auto;
      }
    }

    .teaser__content {
      .teaser__title {
        padding-right: 3rem;

        h3 {
          text-align: left;
        }
      }

      .teaser__subtitle {
        padding-right: 3rem;

        p {
          text-align: left;
        }
      }

      .teaser__text {
        padding-right: 2rem;

        a {
          p {
            text-align: left;
          }
        }
      }

      .teaser__date {
        text-align: center;
      }

      a.btn {
        margin: 0;
      }
    }
  }
}
