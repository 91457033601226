label {
  &[role='select'] {
    position: relative;

    & > .label {
      height: 0;
      line-height: 0;
      padding: 0;
      overflow: hidden;

      strong {
        padding: 1.2rem 1.6rem 0;
        line-height: 1.4;
        text-align: left;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 300ms ease-in-out, color 300ms ease-in-out;
      }
    }

    select {
      background-color: transparent;
      color: $color__black;
      border-radius: 0;
      font-size: 1.7rem;
      line-height: 2.4rem;
      font-family: $base--font-family;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      margin: 2.3rem 1rem 2.3rem 1.6rem;
      position: relative;
      z-index: 100;
      transition: height 300ms ease-in-out;
      border: 0;
      outline: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';

      &:focus:valid {
        outline: 0;
      }

      &::-ms-expand {
        display: none;
      }

      option {
        color: $color__black;
      }

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: 0;
        color: $color__black;

        &::-ms-value {
          color: $color__black;
          background-color: transparent;
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0.2rem solid $color__black--60;
      transition: border-color 300ms ease;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: 10;
      right: 1.6rem;
      top: 50%;
      transform: translateY(-50%);
      background: no-repeat center center;
      background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="' + $color__black--60 + '" d="M27.6 10.667l-11.6 11.6-11.467-11.6-1.067 1.067 12.533 12.4 12.533-12.4z"></path></svg>');
      width: 1.6rem;
      height: 1.6rem;
    }

    &.has-content {
      .label {
        height: auto;

        strong {
          opacity: 1;
        }
      }

      select {
        margin: 0.4rem 1rem 1.2rem 1.6rem;
      }
    }

    &.is-active {
      &::before {
        transform: translateY(-50%) rotate(180deg);
      }

      &::after {
        border-color: $color__black;
      }

      & > .label {
        strong {
          color: $color__black;
        }
      }
    }
  }
}
