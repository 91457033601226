html,
body {
  font-family: $base--font-family;
  font-weight: $base--font-weight-regular;
  font-style: normal;
  font-size: 10px;
  line-height: 1.6;
  color: $color__black;
}

body,
input,
select,
button,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $base--font-family;
  font-weight: $base--font-weight-bold;
  font-style: normal;
  text-rendering: auto;
  color: $color__black;
  line-height: 1.6;

  &.fw-light {
    font-weight: $base--font-weight-light;
  }

  small {
    font-style: inherit;
    text-rendering: inherit;
    color: inherit;
    line-height: inherit;
    font-size: inherit;
    font-weight: $base--font-weight-light;
  }

  .multiple-headlines & {
    margin: 0;
  }
}

h1,
.h1 {
  font-size: 4.8rem;
  line-height: 1.21;
  font-weight: $base--font-weight-bold;
  margin: 0 0 2rem;

  @media screen and (min-width: $mq-m) {
    margin: 0 0 2.4rem;
    font-size: 5.6rem;
    line-height: 1.1;
  }

  @media screen and (min-width: $mq-l) {
    font-size: 7.0rem;
    line-height: 1.08;
  }

  .multiple-headlines & {
    &:last-child {
      margin-bottom: 0.9rem;
    }
  }
}

h2,
.h2 {
  font-size: 2.4rem;
  line-height: 1.33;
  font-weight: $base--font-weight-bold;
  margin: 0 0 1.6rem;

  @media screen and (min-width: $mq-m) {
    margin: 0 0 2rem;
    font-size: 3.2rem;
    line-height: 1.19;
  }

  @media screen and (min-width: $mq-l) {
    font-size: 4rem;
    line-height: 1.1;
  }
}

h3,
.h3 {
  font-size: 2rem;
  line-height: 1.4;
  font-weight: $base--font-weight-bold;
  margin: 0 0 1.2rem;

  @media screen and (min-width: $mq-m) {
    margin: 0 0 1.6rem;
    font-size: 2.4rem;
    line-height: 1.33;
  }

  @media screen and (min-width: $mq-l) {
    font-size: 2.8rem;
    line-height: 1.21;
  }
}

h4,
.h4 {
  font-size: 2rem;
  line-height: 1.4;
  margin: 0 0 0.8rem;

  @media screen and (min-width: $mq-m) {
    margin: 0 0 1.2rem;
    font-size: 2.4rem;
    line-height: 1.33;
  }

  @media screen and (min-width: $mq-l) {
    font-size: 2.8rem;
    line-height: 1.21;
  }
}

h5,
.h5 {
  font-size: 1.7rem;
  line-height: 1.41;
  font-weight: $base--font-weight-bold;
  margin: 0 0 0.4rem;

  @media screen and (min-width: $mq-m) {
    margin: 0 0 0.8rem;
    font-size: 2rem;
    line-height: 1.4;
  }

  @media screen and (min-width: $mq-l) {
    font-size: 2rem;
    line-height: 1.4;
  }
}

h6,
.h6 {
  font-size: 1.7rem;
  line-height: 1.41;
  margin: 0 0 0.2rem;

  @media screen and (min-width: $mq-m) {
    margin: 0 0 0.4rem;
    font-size: 2rem;
    line-height: 1.4;
  }

  @media screen and (min-width: $mq-l) {
    font-size: 2rem;
    line-height: 1.4;
  }
}

i {
  font-style: italic;
}

b,
strong {
  font-weight: $base--font-weight-bold;
}

span,
label {
  font-weight: inherit;
  font-size: 2rem;
  line-height: 1.6;
  text-rendering: auto;
}


figcaption {
  font-size: 2rem;
  line-height: 1.6;
  font-family: $base--font-family;
  font-weight: $base--font-weight-regular;
}

q {
  font-weight: $base--font-weight-bold;

  &::before {
    content: '\00ab';
  }

  &::after {
    content: '\00bb';
  }
}

a {
  color: inherit;
  font-weight: $base--font-weight-regular;
}

p {
  font-family: $base--font-family-secondary;
  font-weight: $base--font-weight-light;
  font-size: 1.7rem;
  line-height: 1.41;
  margin-top: 0;
  margin-bottom: 1.2rem;
  text-rendering: auto;

  @media screen and (min-width: $mq-m) {
    font-size: 2rem;
    line-height: 1.4;
  }

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: $base--font-weight-bold;
  }

  a,
  a * {
    &:not([role='button']) {
      text-decoration: underline;
      color: inherit;
    }
  }

  a {
    font-weight: $base--font-weight-regular;

    &:not([role='button']) {
      &:hover {
        color: inherit;
        opacity: 0.6;
        text-decoration: underline;
      }

      &:active {
        opacity: 0.6;
        text-decoration: underline;
        color: inherit;
      }
    }
  }
}

pre {
  font-size: 1.4rem;
  line-height: 1.6;
}

code {
  font-size: 1.4rem;
  line-height: 1.6;
}

ul,
ol {
  list-style: none;
  list-style-position: outside;
  margin-left: 0;
  padding-left: 1.6em;
  font-size: 2rem;
  font-weight: $base--font-weight-light;
  line-height: 1.6;

  li {
    margin-bottom: 1.2rem;
  }

  ul,
  ol {
    margin-top: 1.2rem;
    margin-left: 0;
  }
}

ul:not([role]) {
  list-style: none;

  li {
    position: relative;

    &::before {
      content: '\2013';
      display: inline-block;
      position: absolute;
      top: 0;
      left: -1.6em;
    }
  }
}

ol:not([role]) {
  list-style: none;
  counter-reset: ol-counter;

  li {
    position: relative;

    &::before {
      content: counter(ol-counter) '.';
      counter-increment: ol-counter;
      display: inline-block;
      position: absolute;
      top: 0;
      left: -1.6em;
    }
  }
}

hr {
  border-width: 0.1rem;
  border-style: solid;
  border-color: $color__black;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

small {
  font-size: 1rem;
}

blockquote {
  border-left: 0.5rem solid $color__black;
  padding-left: 3.2rem;

  p {
    font-size: 2.4rem;
    line-height: 1.3333333;
    position: relative;
    font-family: $base--font-family;
    font-weight: $base--font-weight-bold;
    color: $color__black;
    margin: 1.5rem 0 1.6rem;

    &::before {
      content: '\00ab';
      font-size: 1em;
      display: inline-block;
      padding-right: 0.2rem;
    }

    &::after {
      content: '\00bb';
      font-size: 1em;
      display: inline-block;
      padding-left: 0.2rem;
    }
  }

  footer {
    width: 75%;
    font-size: 1.7rem;
    font-family: $base--font-family;
    font-weight: $base--font-weight-light;
    color: $color__black--60;
  }
}
