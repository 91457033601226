.accordion {
  display: block;
  margin: 0;
  padding: 0 0.4rem;

  .accordion__item {
    display: block;
    border-bottom: 0.1rem solid $color__black;

    h2 {
      display: block;
      min-height: 6.6rem;
      margin: 0;
      padding: 1.2rem 5rem 1rem 0;
      position: relative;
      font-size: 1.6rem;
      line-height: 1.4;
      font-family: 'lexicon_no1', Times, serif;
      font-weight: 700;
      cursor: pointer;
      color: $color__black;
      text-align: left;

      span {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          z-index: -1;
          left: 0;
          bottom: -0.2em;
          height: 0;
          width: 100%;
          background: $color__secondary--default;
          transition: height, 200ms;
        }
      }

      svg {
        transform: rotate(90deg);
        position: absolute;
        right: 0;
        bottom: 0.8em;
        color: $color__white;
        transition: transform, 200ms;
      }

      /* &:hover,
      &:active {
        //color: $color__black--10;

        svg {
          color: $color__secondary--default;
        }
      } */
    }

    h3,
    h4,
    h5 {
      font-size: 1.4rem;
    }

    h6 {
      font-size: 1.6rem;
    }

    .accordion__content {
      display: none;

      .content {
        p {
          text-align: left;
          font-weight: 300;
          font-family: Akkurat, Helvetica, Arial, sans-serif;
        }
      }

      a {
        svg {
          color: transparent;
          width: 1.4em;
        }

        &:hover {
          &::before {
            display: none;
          }

          svg {
            color: $color__secondary--default;
          }
        }
      }
    }

    &:hover,
    &:active {
      h2 {
        span {
          &::after {
            height: 0.8em;
          }
        }

        svg {
          //color: $color__secondary--default;
          //transform: rotate(-90deg);
        }
      }
    }

    // STATES
    &.accordion--open {
      h2 {
        //color: $color__primary--default;

        span {
          &::after {
            height: 0.8em;
          }
        }

        svg {
          //color: $color__secondary--default;
          transform: rotate(-90deg);
        }
      }

      .accordion__content {
        display: block;
      }
    }
  }
}

// NO-JS Fallback
.no-js {
  .accordion__content {
    display: block;
  }
}

@media screen and (min-width: $mq-m) {
  .accordion {
    .accordion__item {
      h2 {
        font-size: 2.2rem;
      }
    }
  }
}
