.slider-case {
  @include clearfix;
  position: relative;
  margin: 0 auto;
  width: 100%;

  .flickity-viewport {
    position: absolute;
    width: 100%;
  }

  .slider__inner {
    @include full;
    position: relative;
    height: 100%;
  }

  .slider__items {
    @include clearfix;
    height: 55vh;
    max-height: 50rem;
  }

  .slider__item {
    width: 100%;
    height: 100%;
  }

  .flickity-page-dots {
    display: none;
  }

  .slider__content {
    position: absolute;
    top: calc(50% - 6rem);
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-height: 50%;
    min-width: 32rem;
    max-width: 80rem;
    width: 60%;
    height: 60%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .slider__content-wrap {
      width: 100%;

      a {
        max-width: 80rem;
        display: block;
        margin: 0 auto;
        padding: 8rem;

        h2 {
          text-align: center;
          font-size: 3.2rem;
          line-height: 1.8;
          color: $color__white;
          font-weight: bold;
          font-family: Akkurat, Helvetica, Arial, sans-serif;
          display: inline;
          position: relative;
        }

        h3 {
          text-align: center;
          font-size: 3.2rem;
          line-height: 1.2;
          color: $color__white;
          font-family: 'lexicon_no1', Times, serif;
          display: inline;
          position: relative;
        }

        p {
          display: none;
        }

        i {
          margin-top: 1.6rem;
          display: inline-block;

          svg.icon {
            width: 3rem;
            height: 3rem;
            color: transparent;
            fill: $color__white;
          }
        }

        &:hover,
        &:active {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .slider__image-container {
    width: 100%;
    height: 100%;
    position: relative;

    figure,
    picture,
    .video {
      width: auto;
      height: 100%;

      video {
        width: auto;
        padding-bottom: 0;
      }
    }
  }

  img,
  video {
    transition: opacity 0.4s;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    z-index: -100;
    object-fit: contain;
    // Polyfill-Notation for object-fit-images (IE11)
    font-family: 'object-fit: contain;';

    &.flickity-lazyloaded,
    &.flickity-lazyerror {
      opacity: 1;
      background-image: none;
    }

    // IE fix
    &[data-flickity-lazyload] {
      width: auto;
    }
  }

  // Prev/Next navigation
  .flickity-prev-next-button {
    display: none;

    .arrow {
      fill: $color__white;
    }

    &:focus {
      box-shadow: 0 0 0.2rem 0.2rem #09f;
    }
  }

  .slider__nav-item {
    width: 2.4rem;
    height: 2.4rem;
    clear: none;
    display: inline-block;
    margin: 0 0.6rem;
    background-color: $color__primary--default;
    border: 0.6rem solid $color__white;
    border-radius: 0.4rem;
  }

  .flickity-page-dots {
    bottom: -4rem;
  }

  .dot {
    width: 2rem;
    height: 2rem;
    opacity: 1;
    background: transparent;
    border: 0.2rem solid $color__black;
    position: relative;
    margin: 0 0.4rem;

    &:hover {
      background-color: $color__black--10;
    }

    &.is-selected::before {
      background-color: $color__black;
    }
  }

  &.left {
    .slider__content {
      transform: translateX(0) translateY(0);
      left: 0;
      top: 50%;
      width: 100%;
      height: 40%;

      .slider__content-wrap {
        width: 100%;

        a {
          max-width: 40rem;
          text-align: left;
          width: 90%;
          display: block;
          margin: 0;

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: $color__black;
            text-align: left;
            width: 90%;
          }

          svg.icon {
            fill: $color__black;
          }

          &:hover,
          &:active {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
              &::before {
                display: inline-block;
              }
            }

            &::after {
              display: none;
            }
          }
        }
      }
    }

    .slider__image-container {
      width: 100%;
      height: 50vh;
      left: 0;
    }
  }

  &.right {
    .slider__content {
      transform: translateX(0) translateY(0);
      left: 0;
      top: 50%;
      width: 100%;
      height: 40%;

      .slider__content-wrap {
        width: 100%;

        a {
          max-width: 40rem;
          text-align: left;
          width: 90%;
          display: block;
          margin: 0;

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: $color__black;
            text-align: left;
            width: 90%;
          }

          svg.icon {
            fill: $color__black;
          }

          &:hover,
          &:active {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
              &::before {
                display: inline-block;
              }
            }

            &::after {
              display: none;
            }
          }
        }
      }
    }

    .slider__image-container {
      width: 100%;
      height: 50vh;
      left: 0;
    }
  }
}

@media screen and (min-width: $mq-m) {
  .slider-case {
    &.left {
      .slider__content {
        transform: translateX(0) translateY(-50%);
        left: 50%;

        .slider__content-wrap {
          width: 100%;

          a {
            max-width: 40rem;
            text-align: left;
            width: 90%;
            display: block;
            margin: 0;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: $color__black;
              text-align: left;
              width: 90%;
            }

            svg.icon {
              fill: $color__black;
            }

            &:hover,
            &:active {
              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              p {
                &::before {
                  display: inline-block;
                }
              }
            }
          }
        }
      }

      .slider__image-container {
        width: 50%;
        height: 100%;
      }
    }

    &.right {
      .slider__content {
        transform: translateX(-40rem) translateY(-50%);
        left: 50%;
        width: 50%;

        .slider__content-wrap {
          width: 100%;

          a {
            max-width: 40rem;
            text-align: left;
            width: 90%;
            display: block;
            margin: 0;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: $color__black;
              text-align: left;
              width: 90%;

              // &::before {
              //   bottom: -0.2em;
              //   background: $color__secondary--default;
              //   height: 1em;
              //   display: none;
              // }
            }

            svg.icon {
              fill: $color__black;
            }

            &:hover,
            &:active {
              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              p {
                &::before {
                  display: inline-block;
                }
              }

              &::after {
                display: none;
              }
            }
          }
        }
      }

      .slider__image-container {
        width: 50%;
        left: 50%;
        height: 100%;
      }
    }
  }
}
