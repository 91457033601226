.footer {
  // position: fixed;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  z-index: 1000;
  left: 0;
  width: 100vw;
  height: 22rem;
  text-transform: uppercase;
  color: #c1c1c1;
  justify-content: center;

  .footer-background {
    width: 100%;
    background: $color__black;
    padding: 2rem;
  }

  // Do not display footer logo (Dragon) on mobile devices
  .footer-logo {
    display: none;

    @media screen and (min-width: #{$mq-m}) {
      display: block;
    }
  }

  @media screen and (max-width: $mq-s) {
    height: 28rem;
  }

  .language-picker-footer {
    display: none;
    
    @media screen and (max-width: $mq-s) { 
      display: flex;
      padding-top: 15px;


      a.language-seperator-footer {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        font-size: 1.2rem;
        font-weight: $base--font-weight-bold;
      }
  
      a.en-picker-footer {
        padding-left: 0.5rem;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: $base--font-weight-bold;
        
  
        &:hover {
          -webkit-transition: all 0.8s;
          transition: all 0.1s;
          color: #9e7b3d;
        }

        &.lang-selected {
          color: #9e7b3d;
          cursor: default;
        }

      }
  
      a.de-picker-footer {
        padding-right: 0.5rem;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: $base--font-weight-bold;
  
        &:hover {
          -webkit-transition: all 0.8s;
          transition: all 0.1s;
          color: #9e7b3d;
        }

        &.lang-selected {
          color: #9e7b3d;
          cursor: default;
        }
      }
    }
  }

  @media screen and (max-width: $mq-s) { 
    align-items: center;
  }

  .footer-nav-links {
      display: flex;
      flex-grow: 1;
      flex-wrap: nowrap;
      text-align: center;

      @media screen and (max-width: $mq-s) { 
        padding-top: 1rem;
        text-align: center;
        order: 2;
        flex-wrap: wrap;
        justify-content: center;
      }
  }

  a.footer-links {
    text-decoration: none;
    font-size: 1.2rem;
    padding-right: 2.5rem;
    font-weight: $base--font-weight-bold;

    @media screen and (max-width: $mq-s) { 
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    &:hover {
      -webkit-transition: all 0.8s;
      transition: all 0.1s;
      color: #9e7b3d;
    }
  }

  .footer-right-links {
    display: flex;
    padding: 1rem;
    text-decoration: none;
    justify-content: center;
    
    @media screen and (max-width: $mq-s) {
      width: 100%;
    }
    
  }

  a.facebook-icon {
    padding-right: 1.5rem;
    cursor: pointer;
    svg {
      width: 25px;
      height: 25px;
      fill: white;
      opacity: 0.8;

      &:hover {
        -webkit-transition: all 0.8s;
        transition: all 0.1s;
        opacity: 1;
      }
    }
  }

  a.pinterest-icon {
    cursor: pointer;
    svg {
      width: 25px;
      height: 25px;
      fill: white;
      opacity: 0.8;

      &:hover {
        -webkit-transition: all 0.8s;
        transition: all 0.1s;
        opacity: 1;
      }
    }
  }
  a.instagram-icon {
    cursor: pointer;
    svg {
      width: 25px;
      height: 25px;
      fill: white;
      opacity: 0.8;

      &:hover {
        -webkit-transition: all 0.8s;
        transition: all 0.1s;
        opacity: 1;
      }
    }
  }
}

body {
  padding-bottom: 5rem;
}

.footer-wrap {
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-direction: row;
}

.footer > *,
.footer .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
