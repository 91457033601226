.search-list {
  display: flex;
  font-family: $base--font-family;

  .error {
    text-align: center;
    font-size: 1.6rem;
    padding: 4rem 0;
  }

  p {
    border-left: 1.5rem solid transparent;
  }

  .records-found {

  }

  h3.search-list-title {
    font-weight: bold;
    font-size: 1.6rem;
  }

  .search-list-items-wrapper {
    display: flex;
  

    .search-list-item {
      .teaser__item {
        .teaser__content {
          padding: 0.8rem 0;

          .teaser__subtitle {
            font-style: normal;
            font-weight: bold;
            text-rendering: auto;
            text-decoration: none;
            color: $color__black;
            text-align: left;
            font-size: 2.4rem;
            line-height: 1.4;
            display: inline;
            margin: 1.2rem 0 0.8rem 0;
          }

          .teaser__text {
            display: block;

            a {
              display: inline;
              font-size: 2rem;

              mark {
                background: $color__secondary--default;
                padding: 0 0.4rem;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}

.search-result-title-wrapper {
  .search-list-title {
    text-align: center;
    margin-top: 7rem;
    margin-bottom: 0px;
    color: black;
    font-size: 30px;
    border-left: 1.5rem solid transparent;
    font-family: $base--font-family;
    text-transform: uppercase;
  }
  
  .records-found {
    color: black;
    text-align: center;
    border-left: 1.5rem solid transparent;
    font-size: 20px;
    text-transform: uppercase;
  }
}