.category-overview {
  .category-overview-title,
  .category-overview-show-all {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }

  .category-overview-items-wrapper {
    width: 100%;
    margin: 0 auto;

    .category-overview-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 0.4rem;
      margin: 0 auto 2rem;
      color: $color__black;
      text-decoration: none;

      .category-overview-item-image-wrapper {
        padding-left: 0;
        flex-shrink: 0;
        text-align: center;
        margin-bottom: 0.5rem;
        width: 100%;
        height: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          // Polyfill-Notation for object-fit-images (IE11)
          font-family: 'object-fit: contain;';
          max-width: 16rem;
          max-height: 16rem;
        }

        svg {
          color: $color__white;
        }

        svg.icon--l {
          width: 100%;
          height: 100%;
          object-fit: contain;
          // Polyfill-Notation for object-fit-images (IE11)
          font-family: 'object-fit: contain;';
          max-width: 16rem;
          max-height: 16rem;
        }

        svg:not(.icon--l) {
          width: 100%;
          height: 100%;
          object-fit: contain;
          // Polyfill-Notation for object-fit-images (IE11)
          font-family: 'object-fit: contain;';
          max-width: 8rem;
          max-height: 8rem;
          text-align: left;
        }

        &.show-all {
          transform: translate(0, 20%);
          height: 100%;
        }
      }

      .category-overview-item-title {
        font-family: Akkurat, Helvetica, Arial, sans-serif;
        font-weight: bold;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.4;
        position: relative;
        display: inline;
      }

      .category-overview-item-link {
        svg {
          color: $color__white;
          width: 1.8rem;
        }
      }

      &:hover,
      &:active {
        &::after {
          display: none;
        }

        svg {
          color: $color__secondary--default;
        }

        .category-overview-item-title {
          &::after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            bottom: -0.4rem;
            width: 100%;
            height: 0.1rem;
            border-bottom: 1.4em solid $color__secondary--default;
          }
        }
      }
    }
  }
}
