.font-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
    padding: 2rem;
    background: #fff;
    border: 0.1rem solid #bbb;
    text-align: center;

    p {
      margin: 0;
      width: 12rem;

      span,
      i {
        font-size: inherit;
        line-height: initial;
        padding: 0;
        margin: 0;
      }
    }

    small {
      display: block;
      margin: 0 0 2rem;
      padding: 0;
      color: #bbb;
      font-size: 1.2rem;
      line-height: initial;

      &:first-child {
        margin: 0;
      }

      &:last-child {
        margin: 0 0 1rem;
      }
    }
  }
}

// Fonts
/* stylelint-disable */
@mixin font-face($name, $filepath, $weight:normal, $style:normal) {
  @font-face {
    font-family: '#{$name}';
    src: url($filepath + '.eot');
    src: url('#{$filepath}.eot?#iefix') format('embedded-opentype'),
    url('#{$filepath}.woff2') format('woff2'),
    url('#{$filepath}.woff') format('woff'),
    url('#{$filepath}.ttf') format('truetype'),
    url('#{$filepath}.svg##{$name}') format('svg');
    font-weight: $weight;
    font-style: $style;
    font-stretch: normal;
  }
}
/* stylelint-enable */

// Special
//@include font-face('FontAwesome', '../fonts/font-awesome/font-awesome', 400, normal);

// Normal
@include font-face('lexicon_no1', '../fonts/lexiconno1-romana-webfont', 400, normal);
//@include font-face('lexicon_no1', '../fonts/lexiconno1-romana-webfont', 400, italic);
@include font-face('lexicon_no1', '../fonts/lexiconno1-romane-webfont', 700, normal);
//@include font-face('lexicon_no1', '../fonts/lexiconno1-romane-webfont', 700, italic);
@include font-face('Akkurat', '../fonts/lineto-akkurat-bold', 700, normal);
//@include font-face('lexicon_no1', '../fonts/lineto-akkurat-bold', 700, italic);
