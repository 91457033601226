.user-single {
  margin-top: 6.4rem;
  position: relative;

  nav {
    a {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      top: 8rem;

      svg {
        color: transparent;
        width: 100%;
        height: 100%;
      }

      &::before {
        display: none;
      }

      &:hover,
      &:active {
        svg {
          color: $color__secondary--default;
        }

        &::before {
          display: block;
          background: transparent;
        }
      }

      &.prev {
        position: absolute;
        left: 0;
      }

      &.next {
        position: absolute;
        right: 0;
      }
    }
  }

  figure {
    margin: 0 auto;
    max-width: 30rem;
    height: auto;
  }

  header {
    margin-bottom: 3.2rem;

    h1 {
      text-align: center;
      font-size: 2.6rem;
      line-height: 1.3em;
      margin-top: 3.2rem;
      margin-bottom: 0;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
      font-size: 1.8rem;
      line-height: 1.3em;
      margin-top: 0;
    }

    p {
      font-family: 'lexicon_no1', Times, serif;
      font-weight: 400;
      text-align: center;
      color: $color__black--20;

      a:not(.btn):not(.ignore-href-style) {
        color: $color__black--20;
      }
    }
  }

  section {
    margin-bottom: 3.2rem;

    p {
      font-family: 'lexicon_no1', Times, serif;
      font-weight: 400;
    }
  }

  footer {
    margin-bottom: 3.2rem;
    text-align: center;
  }
}

@media screen and (min-width: $mq-s) {
  .user-single {
    nav {
      a {
        width: 4rem;
        height: 4rem;
        top: 18rem;

        &.prev {
          left: -4rem;
        }

        &.next {
          right: -4rem;
        }
      }
    }
  }
}
