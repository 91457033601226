$search_nav_border_style: 0.1rem solid rgba($color__black, 0.6);

.search-nav {
  .search-nav-toggle {
    position: absolute;
    top: 0;
    left: 0;

    svg.icon {
      position: absolute;
      top: 1.2rem;
      left: 1.6rem;
      width: 2.8rem;
      height: 2.8rem;

      &.icon--hieronymus-close {
        //position: fixed;
        top: 1.4rem;
        left: 2rem;
        width: 2.4rem;
        height: 2.4rem;
        display: none;
      }
    }

    &::before {
      content: attr(title);
      position: absolute;
      top: 1.8rem;
      left: 4.4rem;
      padding: 0 1rem 1rem;
      color: $color__black;
      font-family: Akkurat, Helvetica, Arial, sans-serif;
      font-size: 1.2rem;
      opacity: 0;
      border: 0;
      transition: opacity, 200ms;
    }
  }
}

@media screen and (min-width: $mq-m) {
  .search-nav {
    .search-nav-toggle {
      &:not(.btn--active):hover {
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.search-nav-modal {
  overflow: hidden;
  top: 0;
  width: 100vw;
  background: $color__white;

  .tingle-modal-box {
    position: absolute;
    top: 3.1rem;
    right: 0;
    left: 0;
    background: transparent;
    overflow: auto;
    width: 100vw;
    height: 100vh;
    transform: translateY(-100%) scale(0);
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    .tingle-modal-box__content {
      padding: 0 0 5rem;
      text-align: center;

      .search-nav-close {
        display: none;
      }

      .header__nav-content-wrap {
        padding: 6rem 2rem 3rem;
        width: auto;
        display: inline-block;
        text-align: center;

        h2 {
          color: $color__black;
          font-family: 'lexicon_no1', Times, serif;
          font-size: 3.8rem;
          margin: 1.2rem 0 5.4rem 0;
          font-weight: 400;
          text-align: center;
        }

        h3 {
          color: $color__black;
          font-family: 'lexicon_no1', Times, serif;
          font-size: 2.4rem;
          margin: 1.2rem 0 4.2rem 0;
          font-weight: 400;
          text-align: center;
        }

        form {
          position: relative;
          margin: 0 auto;
          width: 100%;
          max-width: 40rem;

          input {
            border: none;
            border-bottom: 1px solid $color__black;
            background: transparent;
            color: $color__black;
          }

          button {
            margin-top: 4.2rem;
            width: 100%;
            max-width: 20rem;
          }

        }
      }

      .header__nav-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: small;
        margin-top: 1rem;
        margin-bottom: 1rem;
        flex-grow: 1;
        align-items: flex-end;

        .header__nav-footer-text {
          margin: 1rem;
          display: none;
        }

        .header__nav-footer-link {
          margin: 2rem 1rem 1rem;
          display: block;
          color: $color__white;
          position: relative;

          &::after {
            content: '/';
            display: inline-block;
            position: absolute;
            right: -1.1rem;
          }

          &:last-child::after {
            display: none;
          }

          &.active,
          &:hover {
            color: $color__white;

            &::before {
              display: inline-block;
              top: 100%;
              border-color: $color__white;
              border-width: 0.1rem;
            }
          }
        }
      }
    }
  }

  &.tingle-modal--visible {
    .tingle-modal-box {
      transform: translateY(0) scale(1);
      opacity: 1;
    }
  }
}

body.tingle-enabled {
  header.header {
    // background: $color__white;
    // width: 100vw;

    // .header__logo-link {
    //   &::before {
    //     display: none;
    //   }

    //   svg {
    //     color: $color__white;
    //     fill: $color__white;
    //   }
    // }

    .header__buttons a:not(.btn--active) {
      display: none;
    }
  }

  .search-nav {
    .search-nav-toggle {
      svg.icon--search {
        display: none;
      }

      svg.icon--hieronymus-close {
        display: block;
        // color: $color__white;
        // fill: $color__white;
      }

      &:hover,
      &:active {
        svg.icon--hieronymus-close {
          //color: $color__black;
          //fill: $color__black;
        }
      }
    }
  }
}
