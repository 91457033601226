*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  //overflow-x: hidden;
  font-size: $base--font-size;
}

body {
  //margin-top: 5rem;
  overflow-x: hidden;
  // Set default font size for easier rem calculation
  font-size: $base--font-size;
  background-color: $color__gray--dark;

  // Only on article page
  // Only above 1024px ($mq-l)
  @media screen and (min-width: $mq-l) {
    .container.main {

      .row {
      
        &.article {
          padding-left: 20vh;
          padding-bottom: 30px;
        }
      }
    }
  }

  &.article {
    background-color: white;
  }

  &.search {
    background-color: white;
  }
}