// Overwrite Magnolia default settings
// As example to prevent layout failure in editing mode
/* stylelint-disable */
div.mgnlPlaceholder {
  width: 98.5% !important;
  margin: 0 5% !important;
}

div.mgnlPlaceholder div.mgnlEditor.mgnlPlaceholderBox {
  height: 0 !important;
  border: 0 !important;
}

div.mgnlEditor,
div.mgnlEditorBar.area {
  position: relative !important;
  z-index: 100 !important;
}

div.mgnlEditorBar {
  width: 94% !important;
  //left: 20% !important;
  opacity: 0.4 !important;
  //top: -1em !important;
  transition: opacity 400ms;
  margin: 0 !important;
  max-width: 94vw !important;

  &.area {
    //display: none !important;
    //margin-bottom: 20px !important;
    margin: 0 !important;
  }

  &.mgnlEditorBarStatusIndicator {
    cursor: pointer !important;
  }

  &.component {
    background-color: #333 !important;
    color: white !important;
  }

  &.mgnlEditorBarStatusIndicator:hover,
  &.mgnlEditorBarStatusIndicator.focus {
    opacity: 1 !important;
  }

  .status-indicator {
    margin-left: 0 !important;
  }

  &.area.end {
    margin-left: 28px !important;
  }
}

main {
  &>div.mgnlEditor.mgnlPlaceholder {
    position: relative !important;
    //bottom: 0 !important;
    margin-bottom: 60px !important;
    margin-left: 38px !important;
    cursor: pointer !important;

    &:hover,
    &.focus {
      opacity: 1 !important;

      .mgnlEditorBar.component {
        opacity: 1 !important;
      }
    }
  }
}

.row>.mgnlEditorBar {
  grid-column: span 12;
}

[class*='col-'] {
  &>.mgnlPlaceholder {
    margin-left: 28px !important;
  }
}

body>a[name='top'].disabled+main {
  margin-top: 60px !important;
}
/* stylelint-enable */
