.btn-primary {
  border-radius: 3px;
  color: $color__white;
  background: $color__black--80;
  transition: background-color 300ms ease-in-out;

  &:hover,
  &.active {
    background: $color__black;
  }

  &:disabled,
  &.disabled {
    background: $color__black--20;

    &:hover,
    &.active {
      background: $color__black--20;
      box-shadow: none;
    }
  }
}
