.section-title {
  display: block;
  text-align: center;

  .section-title__text {
    display: inline-block;
    padding: 0.4rem 4rem 1.5rem;
    font-size: 1.4rem;
    border-bottom: 0.1rem solid $color__black;
  }
}

@media screen and (min-width: $mq-m) {
  .section-title {
    .section-title__text {
      margin-top: 3.2rem;
      margin-bottom: 1.8rem;
    }
  }
}
