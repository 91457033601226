.news-article {
  margin: 0 0 4rem;

  header {
    padding: 0 1rem;

    h1 {
      font-family: 'lexicon_no1', Times, serif;
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 1.4;
      margin: 1.2rem 0 1.8rem;
      text-align: center;
    }

    h4 {
      font-family: Akkurat, Helvetica, Arial, sans-serif;
      font-weight: normal;
      font-style: normal;
      text-rendering: auto;
      font-size: 1.4rem;
      line-height: 1.4;
      margin: 1.2rem 0 0.4rem;
      text-align: center;
    }

    p {
      text-align: center;

      a:not(.btn):not(.ignore-href-style) {
        font-family: 'lexicon_no1', Times, serif;
        color: $color__black--20;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.4;
        text-align: center;

        b {
          font-weight: 600;
        }

        svg {
          fill: $color__black--20;
          color: $color__white;
        }

        &:hover,
        &:active {
          svg {
            color: $color__secondary--default;
          }
        }
      }

    }
  }

  section {
    padding: 0 1rem;

    h2 {
      font-family: 'lexicon_no1', Times, serif;
      font-weight: 700;
      font-size: 2rem;
      line-height: 1.4;
      margin: 3.2rem 0 1.2rem;
      text-align: center;
    }

    h3 {
      font-family: 'lexicon_no1', Times, serif;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 1.4;
      margin: 2.4rem 0 1.2rem;
    }

    h4 {
      font-family: 'lexicon_no1', Times, serif;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.4;
      margin: 3.2rem 0 0.8rem;
      text-align: center;
    }

    p {
      font-family: 'lexicon_no1', Times, serif;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 1.4;
      margin: 1.2rem 0 1.6rem;
    }
  }
}
