.input-addon {
  display: flex;

  &__field {
    flex: 1;
  }

  &__item {
    margin-bottom: 0;
  }
}

// INPUT WITH ICON BUTTON INSIDE
.input-addon--overlay {
  position: relative;

  .input-addon__input {
    padding-right: 5rem;
  }

  .input-addon__item {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
    color: $color__black;

    &,
    &:hover,
    &:active {
      background-color: transparent;
    }

    &:hover {
      color: $color__black--20;
    }
  }
}
