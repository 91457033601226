ul {
  li.has-sub {
    position: relative;

    .icon.toggle-sub {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      svg {
        fill: $color__black;
      }
    }
  }
}
