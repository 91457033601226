.teaser_imagecaption {
  a {
    text-decoration: none;
    //height: 100%;
    display: inline-block;

    &:hover {
      text-decoration: none;

      &::before {
        display: none;
        height: 0;
      }
    }

    &:active {
      text-decoration: none;
    }
  }

  figure {
    height: 100%;
    position: relative;

    &::before {
      content: ' ';
      display: inline-block;
      padding-top: auto;
    }

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 100%;
      object-fit: contain;
      // Polyfill-Notation for object-fit-images (IE11)
      font-family: 'object-fit: contain;';
    }
  }

  header {
    //display: inline-block;
    display: none;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: Akkurat, Helvetica, Arial, sans-serif;
      font-size: 1rem;
      line-height: 1.4;
      margin: 0;
      color: $color__black--20;
      position: relative;
      display: inline-block;

      b {
        font-family: inherit;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        right: -0.3rem;
        bottom: -0.2em;
        left: -0.3rem;
        background: $color__secondary--default;
        width: calc(100% + 0.6rem);
        height: 1em;
        display: none;
      }
    }

    p {
      font-family: 'lexicon_no1', Times, serif;
      margin-top: 0;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.4;
      margin-bottom: 0;
      text-rendering: auto;
      color: $color__black--20;
      display: inline-block;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        right: -0.3rem;
        bottom: -0.2em;
        left: -0.3rem;
        background: $color__secondary--default;
        width: calc(100% + 0.6rem);
        height: 1em;
        display: none;
      }
    }
  }

  section {
    margin: 0 1rem 1rem 0;
    font-family: Akkurat, Helvetica, Arial, sans-serif;
    font-weight: 400;

    p {
      font-family: 'lexicon_no1', Times, serif;
      font-size: 1.4rem;
      text-align: left;
      line-height: 1.4;
      color: $color__black--20;
      position: relative;
      display: inline-block;
      margin: 0;

      b {
        font-weight: bold;
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        right: -0.3rem;
        bottom: -0.2em;
        left: -0.3rem;
        background: $color__secondary--default;
        width: calc(100% + 0.6rem);
        height: 1em;
        display: none;
      }
    }
  }

  &.teaser__item-img-cover {
    height: 100%;

    img {
      object-fit: cover;
      // Polyfill-Notation for object-fit-images (IE11)
      font-family: 'object-fit: cover;';
    }
  }

  footer {
    .teaser__date {
      display: inline-block;
      width: 100%;
      text-align: center;
      font-family: Akkurat, Helvetica, Arial, sans-serif;
    }
  }

  a {
    &:hover,
    &:active,
    &.active {
      header {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          &::before {
            display: inline-block;
          }
        }
      }

      section {
        p {
          &::before {
            display: inline-block;
          }
        }
      }

      footer {
        svg.icon {
          color: $color__secondary--default;
        }
      }
    }
  }

  &.xl {
    header {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 2.2rem;
      }

      p {
        font-size: 1.5rem;
      }
    }

    section {
      p {
        font-size: 1.8rem;
      }
    }
  }

  &.xxl {
    header {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 2.6rem;
      }

      p {
        font-size: 1.9rem;
      }
    }

    section {
      p {
        font-size: 2.2rem;
      }
    }
  }
}

@media screen and (min-width: $mq-s) {
  .teaser {
    figure {
      &::before {
        padding-top: auto;
      }
    }
  }
}
