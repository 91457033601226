.footer__contact {
  .footer__social {
    ul {
      list-style: none;
      display: inline-block;
      margin: 0.8rem 0;
      padding: 0;
      text-align: right;
      width: 100%;

      li {
        display: inline-block;
        padding-left: 1.6rem;
        margin: 0;

        &::before {
          display: none;
        }

        a {
          font-family: Akkurat, Helvetica, Arial, sans-serif;
          font-weight: 300;
          font-size: 1.4rem;

          i {
            color: $color__white;
          }

          // &::after {
          //   content: attr(title);
          //   display: inline-block;
          //   padding: 0 0 0 0.25rem;
          //   color: $color__white;
          // }
        }
      }
    }
  }
}

@media screen and (min-width: $mq-s) {
  .footer__contact {
    .footer__social {
      ul {
        li {
          a {
            &::after {
              content: attr(title);
              display: inline-block;
              padding: 0 0 0 0.25rem;
              color: $color__white;
            }
          }
        }
      }
    }
  }
}
