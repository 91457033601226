.btn-secondary {
  border: 2px solid $color__black--20;
  border-radius: 3px;
  color: $color__black--60;
  background: $color__white;
  transition-property: border-color, color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;

  &:hover,
  &.active {
    border-color: $color__black;
    color: $color__black;
  }

  &:disabled,
  &.disabled {
    border-color: $color__black--10;
    color: $color__black--10;

    &:hover,
    &.active {
      border-color: $color__black--10;
      color: $color__black--10;
      box-shadow: none;
    }
  }

  &.btn-text {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;

    @media screen and (min-width: $mq-s) {
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
    }

    @media screen and (min-width: $mq-m) {
      padding-top: 1.1rem;
      padding-bottom: 1.1rem;
    }

    @media screen and (min-width: $mq-l) {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
}
