.slider-imageonly {
  @include clearfix;
  position: relative;
  margin: 0 auto;
  padding-bottom: 4rem;
  width: 100%;

  .flickity-page-dots {
    li.dot {
      border: none;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;

      &::before {
        display: none;
      }

      svg {
        color: $color__white;
      }

      &:hover,
      &.is-selected {
        background: none;

        svg {
          color: $color__secondary--default;
        }
      }
    }
  }

  .slider__item {
    width: 100%;
    height: 100%;

    &.dark,
    &.dark h1 {
      color: $color__white;
    }

    &.bright,
    &.bright h1 {
      color: $color__primary--default;
    }
  }

  .slider__inner {
    @include full;
    position: relative;
    height: 100%;
  }

  .slider__items {
    @include clearfix;
    height: (675/1200)*100vw * 2; //aspect ratio
    //max-height: 26rem;
  }

  .slider__image-container {
    width: 100%;
    height: 100%;
    position: relative;

    figure,
    picture,
    .video {
      width: auto;
      height: 100%;

      video {
        width: auto;
        padding-bottom: 0;
      }
    }
  }

  .slider__content {
    text-align: center;
    background: transparent;
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    top: 0;
    padding: 0 6.6rem 4rem;
    //max-width: 60rem;
    //min-width: 12rem;

    .slider__content-wrap {
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0;
      padding-left: 0;
      margin-left: auto;
      margin-right: auto;

      a {
        display: block;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        p {
          position: absolute;
          z-index: 100;
          bottom: 0;
          left: 50%;
          width: 100%;
          max-width: 48rem;
          transform: translateX(-50%);
          height: auto;
          display: block;
          padding: 1rem 0 0;
          margin: 0;
          font-family: 'lexicon_no1', Times, serif;
          font-size: 1.4rem;
          text-align: left;
          line-height: 1.4;
          color: $color__black--20;

          b {
            font-weight: bold;
          }

          &::before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            right: -100%;
            bottom: 0;
            left: -100%;
            width: 300%;
            height: 100%;
            background: $color__white;
          }
        }

        &:hover {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .flickity-slider {
    position: relative;
  }

  // Prev/Next navigation
  .flickity-prev-next-button {
    background-color: transparent;
    border-radius: 0;
    top: 50%;
    height: 102%;
    width: 5rem;
    border-top: 2px solid $color__white;
    border-bottom: 2px solid $color__white;
    cursor: pointer;
  }

  .flickity-prev-next-button.previous {
    left: 0;
    color: transparent;

    svg {
      color: transparent;
    }

    &:hover,
    &:active {
      svg {
        color: $color__secondary--default;
      }
    }
  }

  .flickity-prev-next-button.next {
    right: 0;

    svg {
      color: transparent;
    }

    &:hover,
    &:active {
      svg {
        color: $color__secondary--default;
      }
    }
  }

  &[min-width ~='500px'] {
    //overflow: hidden;

    .flickity-slider {
      position: absolute;
    }

    .flickity-viewport {
      position: absolute;
      width: 100%;
    }

    .slider__items {
      @include clearfix;
      height: (675/1200)*100vw; //aspect ratio
      //max-height: 26rem;
    }

    .slider__content {
      .slider__content-wrap {
        //padding-left: 3rem;
      }
    }

    .slider__image-container {
      left: 1px;
      width: calc(100% - 2px);
      height: 100%;
    }

    // Prev/Next navigation
    .flickity-prev-next-button {
      background-color: $color__white;
      width: 5rem;
    }

    .flickity-prev-next-button.previous {
      left: -5rem;
      background: $color__white;
      color: $color__white;

      svg {
        color: $color__white;
      }

      &:hover,
      &:active {
        svg {
          color: $color__secondary--default;
        }
      }
    }

    .flickity-prev-next-button.next {
      right: -5rem;
      background: $color__white;

      svg {
        color: $color__white;
      }

      &:hover,
      &:active {
        svg {
          color: $color__secondary--default;
        }
      }
    }
  }

  &[min-width ~='600px'] {
    .slider__content {
      padding-right: 4.4rem;
      padding-left: 4.4rem;
    }
  }

  img,
  video {
    transition: opacity 0.4s;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    z-index: -100;
    object-fit: cover;
    // Polyfill-Notation for object-fit-images (IE11)
    font-family: 'object-fit: cover;';

    &.flickity-lazyloaded,
    &.flickity-lazyerror {
      opacity: 1;
      background-image: none;
    }

    // IE fix
    &[data-flickity-lazyload] {
      width: auto;
    }
  }

  .slider__nav-item {
    width: 2.4rem;
    height: 2.4rem;
    clear: none;
    display: inline-block;
    margin: 0 0.6rem;
    background-color: $color__primary--default;
    border: 0.6rem solid $color__white;
    border-radius: 0.4rem;
  }

  // Dots navigation
  //pageDots: false;

  .flickity-page-dots {
    bottom: -4rem;
  }

  .dot {
    width: 2rem;
    height: 2rem;
    opacity: 1;
    background: transparent;
    border: 0.2rem solid $color__black;
    position: relative;
    margin: 0 0.4rem;

    &::before {
      display: block;
      content: '';
      width: 0.6rem;
      height: 0.6rem;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      border-radius: 100%;
    }

    &:hover {
      background-color: $color__black--10;
    }

    &.is-selected::before {
      background-color: $color__black;
    }
  }
}
