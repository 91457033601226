[role='button'] {
  &.btn-icon-only {
    padding: 0.4rem;
    font-size: 0;
    line-height: 0;

    @media screen and (min-width: $mq-s) {
      padding: 0.8rem;
    }

    @media screen and (min-width: $mq-m) {
      padding: 1.2rem;
    }

    .label {
      .icon {
        display: table-cell;

        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }
}
