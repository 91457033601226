div.back-to-top {
  text-align: center;

  .call-to-action {
    width: auto;
    text-align: center;
    display: inline-block;
    margin-bottom: 0.4rem;
    cursor: pointer;

    figure {
      display: block;
      width: 100%;

      i {
        display: inline-block;

        svg {
          transform: rotate(90deg);
          //color: $color__bright;
        }
      }
    }

    p {
      font-family: 'lexicon_no1', Times, serif;
      width: 100%;
      margin-bottom: 0.4rem;
    }
  }
}

@media screen and (min-width: $mq-s) {
  div.back-to-top {
    .call-to-action {
      display: none;
    }
  }

  // Still visibile for Magnolia editors
  .mgnlEditorBar + div.back-to-top .call-to-action {
    display: block;
    opacity: 0.4;
  }
}
