$switch__width: 8rem;
$switch__height: 4rem;
$switch__inner-space: 0.4rem;

$switch__radius: $switch__height;
$switch__knob-size: $switch__height - $switch__inner-space * 2;

.switch {
  display: inline-block;
  position: relative;
  margin-bottom: 1.8rem;
  cursor: pointer;
  height: $switch__height;
  width: $switch__width;
  user-select: none;
  -webkit-tap-highlight-color: rgba($color__black, 0);
}

.switch__label {
  display: block;
  position: relative;
  height: $switch__height;
  width: 100%;
  cursor: pointer;
  background: $color__black--10;
  border-radius: $switch__radius;
  transition: all 0.15s ease-out;
  transform: translate3d(0, 0, 0);

  &::after {
    content: '';
    display: block;
    background: $color__white;
    position: absolute;
    top: $switch__inner-space;
    left: $switch__inner-space;
    width: $switch__knob-size;
    height: $switch__knob-size;
    border-radius: $switch__radius;
    transition: all 0.15s ease-out;
    transform: translate3d(0, 0, 0);
  }
}

.switch__input {
  display: none;
}

.switch__text {
  @include hide-text;
}

.switch__active,
.switch__inactive {
  color: $color__white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.switch__active {
  display: none;
  left: 15%;
}

.switch__inactive {
  right: 15%;
}

// STATES
.switch:hover {
  .switch__label {
    background: $color__black--20;
  }
}

.switch__input:checked {
  + .switch__label {
    background: $color__primary--default;

    &::after {
      left: $switch__width - $switch__knob-size - $switch__inner-space;
    }

    // stylelint-disable selector-max-specificity
    .switch__inactive {
      display: none;
    }

    .switch__active {
      display: inline;
    }

    // stylelint-enable selector-max-specificity
  }
}
