label {
  &[role='textarea'] {
    align-items: flex-end;

    strong {
      display: none;
    }

    &.fullbleed {
      .text-container {
        width: 100%;
      }
    }

    .text-container {
      width: 100%;
      padding: 2.1rem 3.2rem 0.7rem 1.6rem;

      textarea {
        box-sizing: border-box;
        resize: none;
        width: 100%;
        font-family: $base--font-family;
        font-weight: $base--font-weight-light;
        line-height: 1.4;
        overflow: hidden;
        border: 0;

        &:focus:valid {
          outline: 0;
        }

        &.mirrow {
          position: absolute;
          top: -999rem;
          left: -999rem;
          visibility: hidden;
        }
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 10;
        right: 0;
        bottom: 0;
        display: block;
        background: transparent no-repeat right 0.4rem bottom 0.4rem;
        background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="' + $color__black + '" d="M21.3,2.7v8h8v-8C29.3,2.7,21.3,2.7,21.3,2.7z M28,9.3h-5.3V4H28V9.3z"/><path fill="' + $color__black + '" d="M12,20h8v-8h-8V20z M13.3,13.3h5.3v5.3h-5.3V13.3z"/><path fill="' + $color__black + '" d="M21.3,20h8v-8h-8V20z M22.7,13.3H28v5.3h-5.3V13.3z"/><path fill="' + $color__black + '" d="M2.7,29.3h8v-8h-8C2.7,21.3,2.7,29.3,2.7,29.3z M4,22.7h5.3V28H4V22.7z"/><path fill="' + $color__black + '" d="M12,29.3h8v-8h-8V29.3z M13.3,22.7h5.3V28h-5.3V22.7z"/><path fill="' + $color__black + '" d="M21.3,29.3h8v-8h-8V29.3z M22.7,22.7H28V28h-5.3V22.7z"/></svg>');
        background-size: 1.6rem 1.6rem;
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    &[data-valid-label] {
      &::before {
        top: 0.8rem;
        transform: none;
      }
    }

    &[data-error-label] {
      &::before {
        top: 0.8rem;
        transform: none;
      }
    }

    &.is-active {
      & > .text-container,
      & > .label {
        border-color: $color__black;
      }
    }
  }
}
