.dropdown {
  position: relative;
  margin: 0 0 1.8rem;

  .btn {
    margin-bottom: 0;
  }
}

.dropdown__menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 16rem;
  padding: 0.6rem 0;
  margin: 0.2rem 0 0;
  list-style: none;
  background-color: $color__white;
  border: 0.1rem solid $color__black--10;

  a {
    display: block;
    padding: 0.5rem 1.2rem;
    white-space: nowrap;
  }
}

// STATES
.dropdown--open {
  .dropdown__menu {
    display: block;
  }
}
