label {
  &[role='file'] {
    position: relative;
    margin-top: -0.4rem;
    min-height: 5.2rem;

    div {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        z-index: 120;
        top: 0;
        right: 2.4rem;
        display: block;
        background: no-repeat center center;
        background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="' + $color__black + '" d="M24 18.667v1.333h5.333v10.667h-28v-10.667h5.333v-1.333h-6.667v13.333h30.667v-13.333z"></path><path fill="' + $color__black + '" d="M22.667 12.267v-1.867l-6.667 6.667v-17.067h-1.333v17.067l-6.667-6.667v1.867l7.333 7.333z"></path></svg>');
        width: 2rem;
        height: 2rem;
        cursor: pointer;
      }

      &::before {
        content: attr(data-label);
        width: 16rem;
        z-index: 100;
        padding: 1.6rem 5.4rem 1.6rem 2.4rem;
        font-size: 1.6rem;
        font-family: $base--font-family;
        font-weight: $base--font-weight-light;
        text-align: center;
        background: $color__white;
        color: $color__black;
        cursor: pointer;
        white-space: nowrap;
      }
    }

    input[type='file'] {
      position: absolute;
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    ul[role='list'] {
      list-style: none;
      display: block;
      margin: 0;
      padding: 0;
      transform: translate(1rem, 0);

      li {
        margin: 0;
        padding: 0;
      }
    }
  }
}

ul[role='file'] {
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0.8rem 1.6rem;
    border-top: 0.2rem solid $color__black--40;

    main {
      width: 80%;

      h6 {
        margin: 0;
      }

      p {
        margin: 0;
      }

      progress {
        // Reset the default appearance
        -webkit-appearance: none;
        appearance: none;
        display: none;
        width: 100%;
        height: 1rem;
        margin-bottom: 0.8rem;

        // Only progress bar for Firefox will work. Progress value does not exist for moz.
        &::-moz-progress-bar {
          background: $color__black--10;
        }

        &::-webkit-progress-bar {
          background: $color__black--20;
        }

        &::-webkit-progress-value {
          background: $color__black--10;
          box-shadow: inset 0 -0.1rem 0.2rem rgba($color__black, 0.2), 0 0.1rem 0.2rem 0 rgba($color__black, 0.1);
        }
      }
    }

    aside {
      text-align: right;

      button {
        display: none;
      }
    }

    &.complete {
      main {
        p {
          display: none;
        }
      }

      aside {
        button {
          &.file-upload-delete {
            display: inline-block;
          }
        }
      }
    }

    &.processing {
      main {
        progress {
          display: block;
        }

        h6 {
          display: inline-block;
          font-size: 1.6rem;
          color: $color__black--60;
          min-width: 50%;
        }

        p {
          display: inline-block;
          font-size: 1.6rem;
          color: $color__black--60;
        }
      }

      aside {
        button {
          &.file-upload-stop {
            display: inline-block;
          }
        }
      }
    }
  }
}
