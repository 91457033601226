$form--row-size-margin-bottom: 2.6rem;
$form--row-error-size-height: 2rem;

.form-row {
  height: auto;
  margin-bottom: $form--row-size-margin-bottom;
}

fieldset {
  border: 0;
  margin: 0;
  width: 100%;
  padding: 0 0 2rem;

  // .container {
  //   margin-bottom: 0;
  // }

  & > legend {
    width: 30%;
    max-width: 20rem;
    min-width: 8rem;
    margin: 0;
    font-weight: 300;
    font-size: 1.8rem;
    float: left;

    span {
      display: inline-block;
      width: auto;

      &[title] {
        position: relative;

        &::after {
          content: '';
          display: block;
          transform: scale(0.6) translateY(-1.2rem);
          float: right;
          margin: 0;
          background-image: url('img-sprite.png');
          background-position: -268px -386px;
          width: 36px;
          height: 36px;

        }

        &:hover {
          &::before {
            opacity: 1;
            max-height: 100%;
          }
        }
      }
    }

    &.required {
      &::after {
        content: '*';
        display: inline-block;
        color: $color__black;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  &.inline {
    & > span {
      flex-direction: row;

      & > label {
        width: auto;

        &:not(:first-child) {
          padding-left: 2rem;
        }
      }
    }
  }

  .form__multiple-buttons {
    display: flex;

    [role='button'] {
      margin-left: 0.8rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .form__input-info {
    margin-top: 0.6rem;
  }
}

label {
  // Reversed HMTML placement so we can use the ~ selector for invalid
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  justify-content: flex-end; //Due to reversed alignment end=start
  width: 100%;
  position: relative;
  font-family: $base--font-family;
  font-weight: $base--font-weight-light;

  .label {
    width: 100%;
    justify-content: flex-start;

    strong {
      padding: 0.8rem 0rem;
      font-size: 1.3rem;
      display: inline-block;
      color: $color__black--60;
    }
  }

  & > .label {
    border-top: 0.2rem solid $color__black--60;
    border-right: 0.2rem solid $color__black--60;
    border-bottom: 0;
    border-left: 0.2rem solid $color__black--60;
    transition: border-color 300ms ease;
  }

  & > input,
  & > .text-container {
    border-top: 0;
    border-right: 0.2rem solid $color__black--60;
    border-bottom: 0.2rem solid $color__black--60;
    border-left: 0.2rem solid $color__black--60;
    transition: border-color 300ms ease;
  }

  &[role='checkbox'],
  &[role='radio'],
  &[role='color'] {
    .label {
      border: 0;
    }

    input,
    .text-container {
      border: 0;
    }
  }

  &.inset {
    padding-left: 2em;
  }

  ::placeholder {
    font-size: 1.7rem;
    line-height: 1.41;
    font-weight: $base--font-weight-regular;
    // Need important to fix placeholder color for IE.
    color: $color__black--60 !important; // stylelint-disable-line declaration-no-important
  }

  &.disabled {
    border-color: $color__black--20;

    ::placeholder {
      color: $color__black--40 !important; // stylelint-disable-line declaration-no-important
    }

    input,
    .label {
      border-color: $color__black--20;
    }
  }
}

// Hide in styleguide
// ----------------------------------------
.f-item-preview {
  & > label[for] {
    display: none;
  }
}
