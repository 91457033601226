$maxWidth: rem(1200);

.map {
  display: block;
  width: 100%;
  position: relative;
  padding-bottom: 5.8rem;

  .mapbox {
    display: block;
    width: 100%;
    height: 48.8rem;
    background-color: grey;
  }

  .addressbox-wrap {
    display: none;
    width: 100%;
    margin: 0 auto;
    max-width: $maxWidth;
    position: relative;

    .addressbox {
      position: absolute;
      z-index: 100;
      top: 0.8rem;
      right: 0;
      background: $color__secondary--default;
      padding: 2rem 2rem 0.4rem;
      //width: 33.333333%;
      width: 40%;
      box-sizing: border-box;

      h2 {
        color: $color__black;
        font-size: 2rem;
        line-height: 1.4;
        margin: 0 0 0.8rem;
        text-align: left;
      }

      p {
        color: $color__black;
        font-size: 1.6rem;
        line-height: 1.35em;
        margin: 0 0 1.6rem;

        a {
          color: inherit;
        }
      }
    }
  }

  .marker-menu {
    position: absolute;
    z-index: 110;
    bottom: 0;
    left: 50%;
    width: 100%;
    max-width: $maxWidth;
    transform: translateX(-50%);
    display: none;

    .show-extern {
      position: absolute;
      z-index: 200;
      right: 4rem;
      bottom: 9.9rem;
      display: block;
      background: $color__white;
      color: $color__black;
      padding: 1.6rem;
      width: 33.333333%;
      min-width: 25rem;
      box-sizing: border-box;
      text-align: center;
      font-size: 1.8rem;
      line-height: 1.4;

      svg {
        color: $color__black;
        fill: $color__black;
        width: 1.6rem;
        margin-left: 1.4rem;
      }

      &:hover {
        background: $color__black;
        color: $color__white;
        text-decoration: none;

        svg {
          background: $color__black;
          color: $color__white;
        }
      }

      &.active,
      &:active {
        background: $color__black;
        color: $color__white;
        text-decoration: none;

        svg {
          background: $color__black;
          color: $color__white;
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      width: 100%;

      li {
        flex: 1;
        flex-grow: 1;
        position: relative;

        a {
          display: block;
          width: 100%;
          background: $color__secondary--default;
          color: $color__black;
          font-size: 1.8rem;
          padding: 1.4rem 0.8rem;
          text-align: center;
          text-transform: uppercase;
          position: relative;

          &::after {
            content: attr(data-iso);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            background: $color__secondary--default;
            color: $color__black;
            font-size: 1.8rem;
            padding: 1.4rem 1.6rem;
            text-align: center;
            text-transform: uppercase;
          }

          &:hover,
          &:hover::after {
            background: $color__black;
            color: $color__white;
            text-decoration: none;
          }

          &.active,
          &:active,
          &.active::after,
          &:active::after {
            background: $color__black;
            color: $color__white;
            text-decoration: none;
          }
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: -0.2rem;
          width: 0.4rem;
          height: 100%;
          background: $color__white;
        }

        &:first-of-type {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $mq-m) {
  .map {
    .marker-menu {
      ul {
        li {
          a {
            font-size: 2.4rem;
            text-indent: auto;

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $mq-l) {
  .map {
    .mapbox {
      height: 60rem;
    }

    .addressbox-wrap {
      display: block;
    }
  }
}

@media only screen and (min-width: $maxWidth) {
  .map {
    .marker-menu {
      .show-extern {
        right: 0;
      }
    }
  }
}
