.header {
  display: flex;
  height: 6rem;
  background: black;
  z-index: 2000;
  top: 0;
  position: fixed;
  left: 0;
  width: 100vw;
  text-transform: uppercase;
  color: #D8D8D8;
  border-bottom: transparent solid 1px;

  .logo-icon {
    width: 15.5rem;
    fill: white;
    opacity: 0.8;

    &:hover {
      -webkit-transition: all 0.8s;
      transition: all 0.1s;
      opacity: 1;
    }
  }

  .hide {
    display: none;
  }
  
  .nav-right-absolute {
    position: absolute;
    right: calc(calc(calc(100% - 1120px)/2) - 85px);
    transform: translate(100%,0);
  }
  
  .logo {
    margin-right: 25px;
  }

  .logo-absolute {
      position: absolute;
      left: calc(calc(calc(100% - 1120px)/2) - 85px); // requirement is to push this beyong 
      transform: translate(-100%,0);	
  }
  
  .page-links {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: $base--font-weight-bold;
    padding-right: 2.5rem;

    &:hover {
      -webkit-transition: all 0.8s;
      transition: all 0.1s;
      color: #9e7b3d;
    }
  }

  .page-links:not(.mobile-only-link) {
    display: initial;
    
    @media screen and (max-width: $mq-l) {
      display: none;  
    }
  }

  .mobile-only-link {
    display: initial;
    
    @media screen and (max-width: $mq-s) { 
      display: none;
    }

    @media screen and (min-width: $mq-l) { 
      display: none;
    }
  }

  .nav-right-links {
    display: flex;
    //padding-right: 3rem;
    text-decoration: none;
    font-weight: $base--font-weight-bold;

    @media screen and (max-width: $mq-l) { 
      padding-right: 0.5rem;
    }

    @media screen and (max-width: 1550px) { 
      padding-right: 0rem;
    }
  }

  a.straight-line-icon {
    padding-right: 0.5rem;
    display: initial;
    svg {
      width: 15px;
      height: 15px;
      fill: white;
      opacity: 0.8;
    }

    @media screen and (max-width: $mq-l) { 
      display: none;
    }
  }

  a.shopping-bag-icon {
    padding-right: 1.5rem;
    cursor: pointer;
    svg {
      width: 15px;
      height: 15px;
      fill: white;
      opacity: 0.8;

      &:hover {
        -webkit-transition: all 0.8s;
        transition: all 0.1s;
        opacity: 1;
      }
    }
  }
  
  a.menu-icon {
    display: none;
    
    @media screen and (max-width: $mq-l) {
      display: block;
      cursor: pointer;
      svg {
        width: 15px;
        height: 15px;
        fill: white;
        opacity: 0.8;
  
        &:hover {
          -webkit-transition: all 0.8s;
          transition: all 0.1s;
          opacity: 1;
        }
      }
    }
  }

  a.search-icon {
    cursor: pointer;
    //padding-right: 12.2rem;
    svg {
      width: 15px;
      height: 15px;
      fill: white;
      opacity: 0.8;

      &:hover {
        -webkit-transition: all 0.8s;
        transition: all 0.1s;
        opacity: 1;
      }

      
    }

    @media screen and (max-width: 1550px) { 
      padding-right: 0;
    }

    @media screen and (max-width: 1200px) { 
      padding-right: 1.5rem;
    }
  }

  .nav-links.mobile-only-links {
    display: flex;
  }

  .nav-links.main-nav {
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
  }

  .search-field {
    display: none;
  }

  .lang-switcher {
    margin-left: auto;
    padding-right: 0.5rem;
    font-weight: $base--font-weight-bold;

    a.language-seperator {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 1.2rem;
      font-weight: $base--font-weight-bold;

      @media screen and (max-width: $mq-l) { 
        display: none;
      }
    }

    a.de-picker {
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: $base--font-weight-bold;
      

      &:hover {
        -webkit-transition: all 0.8s;
        transition: all 0.1s;
        color: #9e7b3d;
      }

      &.lang-selected {
        color: #9e7b3d;
        cursor: default;
      }

      @media screen and (max-width: $mq-l) { 
        display: none;
      }
    }
    
    a.en-picker {
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: $base--font-weight-bold;

      &.lang-selected {
        color: #9e7b3d;;
        cursor: default;
      }

      &:hover {
        -webkit-transition: all 0.8s;
        transition: all 0.1s;
        color: #9e7b3d;
      }

      @media screen and (max-width: $mq-l) { 
        display: none;
      }
    }
  }
}

.header > * {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation {
  width: 100%;
}

.container.navigation {
  @media screen and (max-width: 1640px) { 
    // max-width: 118rem;
    max-width: 135rem;
    padding: 0 11.5rem;
  }

  @media screen and (max-width: 1200px) { 
    max-width: unset;
    padding: 0 3rem;
  }

}

.header.expanded {

  @media screen and (max-width: $mq-l) {
    // border-bottom: solid white 1px;

    .close-menu-icon {
      display: flex;
      fill: #D8D8D8;
      cursor: pointer;
      position: absolute;
      top: 150%;
      right: 3%;
      z-index: 2002;
      svg {
        width: 30px;
        height: 30px;
        opacity: 0.8;

          &:hover {
            -webkit-transition: all 0.8s;
            transition: all 0.1s;
            opacity: 1;
          }
      }
    }

    .page-links:not(.mobile-only-link) {
      margin-left: 50%;
      margin-bottom: 5rem;
      display: flex;
      font-size: 2rem;
      font-weight: 300;
    }
  
    .logo {
      // justify-self: flex-start; 
    }
  
    .nav-links.mobile-only-links {
      margin-right: auto;
      display: flex;
      flex-grow: 1;
    }
  
    .main-nav {
      padding-top: 15%;
      flex-direction: column;
      align-items: flex-start;
      background-color: rgba(34, 34, 34, 0.9);
      width: 100%;
      top: 101%;
      right: 0;
      height: 100vh;
      position: absolute;
      z-index: 2001;

      .close-menu-icon {
        fill: #D8D8D8;
        cursor: pointer;
        position: absolute;
        top: 2%;
        right: 2%;
        svg {
          width: 30px;
          height: 30px;
          opacity: 0.8;
      
            &:hover {
              -webkit-transition: all 0.8s;
              transition: all 0.1s;
              opacity: 1;
            }
        }
      }

      .lang-switcher {
        display: flex;
        width: 30%;
        border-top: 1px solid white;
        padding-top: 40px;
        margin-left: 50%;
        padding-right: initial;
        font-weight: 300;
        // margin-top: 20%;

        a.de-picker {
          display: flex;
          font-size: 2rem;
          font-weight: 300;
        }

        a.en-picker {
          display: flex;
          font-size: 2rem;
          font-weight: 300;
        }
        
        a.language-seperator {
          display: flex;
          font-size: 2rem;
          font-weight: 300;
        }
      }
    }

  }
}

.close-menu-icon {
  display: none;
}

.header-overlay {
  background-color: rgba(34, 34, 34, 0.9);
  width: 100%;
  top: 101%;
  right: 0;
  height: 100vh;
  position: absolute;
  z-index: 2001;
}

.search-wrapper {
  display: none;
}

.header.search-expanded {
  overflow: initial;
  // border-bottom: solid white 1px;

  .close-menu-icon {
    display: flex;
    fill: #D8D8D8;
    cursor: pointer;
    position: absolute;
    top: 150%;
    right: 3%;
    z-index: 2002;
    svg {
      width: 30px;
      height: 30px;
      opacity: 0.8;

        &:hover {
          -webkit-transition: all 0.8s;
          transition: all 0.1s;
          opacity: 1;
        }
    }
  }

  .logo {
    justify-self: flex-start;
  }

  .nav-links.mobile-only-links {
    margin-right: auto;
  }

  .search-wrapper {
    display: block;
    
    .search-field {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 40%;
      left: 50%;
      width: 80vw;
      -moz-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);

      .search-field-input {
        margin-bottom: 15px;
        background: transparent;
        color: white;
        font-size: 34px;
        border: none;
        outline: none;
        line-height: 2;
        font-family: $base--font-family;
        border-bottom: 2px solid #c1c1c1;

        &:focus {
          border-bottom: 2px solid white;
        }
      }

      .search-button {
        color: #ffffff;
        background: #d69327;
        padding: 10px 20px 10px 20px;
        text-decoration: none;
        font-size: 2rem;
        border: none;
        cursor: pointer;

        opacity: 0.8;
          
        &:hover {
          opacity: 1;
        }
      }

      .often-searched-text {
        align-self: center;
        text-align: center;
        width: 100%;
        border-top: 1px solid grey;
        padding-top: 30px;
        margin-top: 30px;
        color: white;
        text-transform: lowercase;
        font-weight: $base--font-weight-semibold;
        opacity: 0.8;
      }

      .often-searched-results {
        font-family: $base--font-family;
        text-transform: none;
        color: white;
        opacity: 0.8;
      }
      
      .often-searched-results-title {
        padding-top: 1.5rem;
        font-family: $base--font-family;
        text-transform: uppercase;
        color: white;
        opacity: 0.8;
        font-weight: $base--font-weight-bold;
      }

    }
  }
}