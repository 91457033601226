.mediateaser {
    cursor: pointer;
    overflow: hidden;
    margin-bottom: $component__bottom-margin;
    background: transparent;
    text-align: left;

    &.search {
        .title {
            color: black;
        }

        .subtitle {
            color: grey;
        }

        // .img-wrapper {
    
        //     .img {
        //         cursor: pointer;
        //         opacity: 1.0;
        //         -webkit-transition: all 0.8s;
        //         transition: all 0.8s;
    
        //         &:hover {
        //             -webkit-transform: scale(1.05);
        //             transform: scale(1.05);
        //             opacity:0.8;
        //         }
        //     }
        // }
    }

    a {
        text-decoration: none;
    }

    .title {
        margin-top: 10px;
        font-size: 25px;
        font-weight: $base--font-weight-bold;
        color: white;
        text-transform: uppercase;
        line-height: 1;
    }

    .subtitle {
        font-family: $base--font-family-secondary;
        font-size: 15px;
        margin-top: 5px;
        
        color: black;
        text-transform: capitalize;
        font-weight: lighter;
        font-style: italic;
        line-height: normal;
    }

    .img-wrapper {
    
        .img {
            cursor: pointer;
            opacity: 0.9;
            -webkit-transition: all 0.8s;
            transition: all 0.8s;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

            &:hover {
                -webkit-transform: translateX(-50%) scale(1.05);
                transform: translateX(-50%) scale(1.05);
                opacity:1;
            }
        }
    }

}