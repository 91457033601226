.link {
  display: inline-block;

  &:hover,
  &:active {
    opacity: 0.6;

    .label {
      strong {
        color: $color__black;
      }
    }
  }

  &.link-color-white {
    color: $color__white;

    .label {
      strong {
        color: $color__white;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.8;
    }
  }
}
