label {
  // STATES
  &[data-info-label] {
    &[role='text'],
    &[role='password'],
    &[role='email'],
    &[role='number'],
    &[role='url'],
    &[role='tel'],
    &[role='time'],
    &[role='date'],
    &[role='datetime'],
    &[role='month'],
    &[role='week'],
    &[role='search'],
    &[role='range'],
    &[role='reset'] {
      &::before {
        content: attr(data-info-label);
        width: auto;
        min-height: 3rem;
        display: block;
        padding: 0.4rem 3.6rem 0.4rem 0;
        font-size: 1.5rem;
        line-height: 1.47;
        font-weight: $base--font-weight-regular;
        color: $color__black;
        padding-right: 2.8rem;
        background: transparent no-repeat right center;
        background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="' + $color__black--40 + '" d="M16,0C7.1,0,0,7.1,0,16s7.1,16,16,16s16-7.1,16-16S24.9,0,16,0z M16,29.7C8.5,29.7,2.3,23.5,2.3,16S8.5,2.3,16,2.3 S29.7,8.5,29.7,16S23.5,29.7,16,29.7z"/><path fill="' + $color__black + '" d="M17.2,24.3v-14h-4.1v2.3h1.7v11.7h-1.7v2.3h5.8v-2.3H17.2z"/><path fill="' + $color__black + '" d="M14.8,5.6h2.3V8h-2.3C14.8,8,14.8,5.6,14.8,5.6z"/></svg>');
        background-size: 1.8rem 1.8rem;
      }
    }

    &[role='select'] {
      .selection-container {
        &::before {
          content: attr(data-info-label);
          width: 100%;
          min-height: 3rem;
          display: block;
          padding: 0.4rem 3.6rem 0.4rem 1.6rem;
          font-size: 1.4rem;
          color: $color__black;
        }
      }
    }

    &[role='textarea'] {
      .text-container {
        &::before {
          content: attr(data-info-label);
          // width: calc(100% + 0.4rem);
          min-height: 3rem;
          display: block;
          padding: 0.4rem 3.6rem 0.4rem 1rem;
          font-size: 1.4rem;
          color: $color__black;
        }
      }
    }
  }

  &[data-error-label] {
    border-color: $color__alert--error;
    background: transparent no-repeat right 0.4rem top 2.6rem;
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="' + $color__alert--error + '" d="M14.667 4h2.667v18.667h-2.667v-18.667z"></path><path fill="' + $color__alert--error + '" d="M14.667 25.333h2.667v2.667h-2.667v-2.667z"></path></svg>');
    background-size: 2.4rem 2.4rem;

    span {
      &.liner {
        &::before {
          border-color: $color__alert--error;
        }
      }
    }

    &[role='text'],
    &[role='password'],
    &[role='email'],
    &[role='number'],
    &[role='url'],
    &[role='tel'],
    &[role='time'],
    &[role='date'],
    &[role='datetime'],
    &[role='month'],
    &[role='week'],
    &[role='search'],
    &[role='range'],
    &[role='reset'] {
      &::before {
        content: attr(data-error-label);
        width: 100%;
        min-height: 3rem;
        display: block;
        padding: 0.4rem 3.6rem 0.4rem 1.6rem;
        font-size: 1.4rem;
        color: $color__white;
        background: $color__alert--error;
        border: 0.2rem solid $color__alert--error;
      }
    }

    &[role='radio'],
    &[role='checkbox'] {
      background-position: right 0.4rem top 0.4rem;

      &::after {
        bottom: -2rem;
      }
    }

    &[role='select'] {
      background: transparent;

      .selection-container {
        &::before {
          content: attr(data-error-label);
          width: 100%;
          min-height: 3rem;
          display: block;
          padding: 0.4rem 3.6rem 0.4rem 1.6rem;
          font-size: 1.4rem;
          color: $color__white;
          background: $color__alert--error;
        }

        select {
          border-color: $color__alert--error;
        }
      }
    }

    &[role='textarea'] {
      .text-container {
        textarea {
          color: $color__alert--error;
          border-color: $color__alert--error;
        }

        &::before {
          content: attr(data-error-label);
          width: auto;
          min-height: 3rem;
          display: block;
          padding: 0.4rem 3.6rem 0.4rem 1rem;
          font-size: 1.4rem;
          color: $color__white;
          background: $color__alert--error;
        }
      }
    }
  }

  &[data-valid-label] {
    background: transparent no-repeat right 0.4rem top 2.6rem;
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="' + $color__alert--info + '" d="M12.667 24.533l-7.6-7.6 1.867-1.867 5.733 5.733 12.4-12.4 1.867 1.867z"></path></svg>');
    background-size: 2.4rem 2.4rem;

    &[role='radio'],
    &[role='checkbox'] {
      background-position: right 0.4rem top 0.2rem;

      &::after {
        bottom: -2rem;
      }
    }

    &.has-content {
      background-position: right 0.4rem top 2.1rem;
    }
  }

  &.disabled {
    border-color: $color__black--20;
  }
}
