$breakpoints: s, m, l;
$sizes: #{$mq-s}, #{$mq-m}, #{$mq-l};

$grid-gap: 1.5rem; // Default gap size
$grid-gap-m: 1.5rem; // New gap size for medium and larger
$grid-demo-color: $color__black--20;
$grid-demo-height: 6.6rem;

.container {
  width: 100%;
  max-width: $page-maxWidth + $container-padding + $container-padding;
  // margin: 0 auto 1.6rem;
  margin: 0 auto;
  padding: 0 $container-padding;

  @media screen and (min-width: $mq-s) {
    max-width: $page-maxWidth + $container-padding-s + $container-padding-s;
    // margin-bottom: 3.2rem;
    padding: 0 $container-padding-s;
  }

  @media screen and (min-width: $mq-m) {
    // margin-bottom: 4.8rem;
  }

  @media screen and (min-width: $mq-l) {
    max-width: $page-maxWidth;
    // margin-bottom: 5.6rem;
    padding: 0 $container-padding-l;
  }
}

.container {
  &.pageheader {
    justify-content: center;
    @media screen and (max-width: $mq-l) {
      padding: 0;   // Page header container is full-width

      .pageheader-wrapper {
          left: 5%;
          top: 60%;

        .lead-text-wrapper {
          left: 5%;
          top: 70%;
        }
      }
    }
  }
}

.container {
  &.article-intro-text {
    @media screen and (min-width: $mq-l) {
      padding: 0;   // Article Intro container is full-width
    }
  }
}

.container {
  &.articleheader {
    @media screen and (max-width: $mq-l) {
      padding-left: 0;
      padding-right: 0;
    }
    padding-top: 60px; // Go past page nav/header
  }
}

.header > .container {
  //padding: 0 10rem;

  @media screen and (min-width: $mq-l) {
    padding: 0 3.2rem;
  }
}

.footer > .container {
  //padding: 0 10rem;
  
  @media screen and (min-width: $mq-l) {
    padding: 0 3.2rem;
  }

  @media screen and (max-width: $mq-s) {
    flex-direction: column;
  }
}

.row {
  display: block;
  font-size: 0;
  line-height: 0;
  width: calc(100% + #{$grid-gap});
  margin-left: -$grid-gap;

  // Align left
  &.row--left {
    margin: 0 auto 0 -$grid-gap;
  }

  // Align center
  &.row--center {
    margin-left: calc(50% - (#{$grid-gap} / 2));
    transform: translateX(-50%);
  }

  // Align right
  &.row--right {
    margin-left: 100%;
    transform: translateX(-100%);
  }

  // Align columns left
  &.row--align-left {
    text-align: left;
  }

  // Center columns
  &.row--align-center {
    text-align: center;
  }

  // Align columns right
  &.row--align-right {
    text-align: right;
  }

  & > [class*='col-'] {
    display: inline-block;
    vertical-align: top;
    font-size: initial;
    line-height: initial;
    width: 100%;
    max-width: 100%;
    margin-top: $grid-gap;
    border-left: $grid-gap solid transparent;

    .row {
      margin-top: -$grid-gap;
    }

    // If no alignent isset reset to default
    &:not([class*='align-']) {
      text-align: left;
    }

    // Add grid gap to any element with class row--gap-bottom
    .row--gap-bottom {
      border-bottom: $grid-gap solid transparent;
    }

    &.row--layout-grey {
      background: $color__black--10;
      position: relative;
      padding: 1.6rem 0;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -1.6rem;
        left: -1.6rem;
        width: calc(100% + 3.2rem);
        height: 100%;
        background: $color__black--10;
      }

      & > * {
        position: relative;
        z-index: 10;
      }
    }
  }

  @media screen and (min-width: $mq-m) {
    width: calc(100% + #{$grid-gap-m});
    margin-left: -$grid-gap-m;

    &.row--start {
      margin-top: -$grid-gap-m;
    }

    &.row--end {
      margin-bottom: $grid-gap-m;
    }

    // Align left
    &.row--left {
      margin-right: -$grid-gap-m;
    }

    // Align center
    &.row--center {
      margin-left: calc(50% - (#{$grid-gap-m} / 2));
    }

    & > [class*='col-'] {
      border-top-width: $grid-gap-m;
      border-left-width: $grid-gap-m;

      // Add grid gap to any element with class row--gap-bottom
      .row--gap-bottom {
        border-bottom-width: $grid-gap-m;
      }
    }
  }

  @for $i from 1 through length($breakpoints) {
    @media screen and (min-width: nth($sizes, $i)) {
      $mq: nth($breakpoints, $i);

      .col-#{$mq}-0 {
        width: 0;
        max-width: 0;
        overflow: hidden;
      }

      @for $j from 1 through 12 {
        .col-#{$mq}-#{$j} {
          max-width: 100% / 12 * $j;
        }
      }
    }
  }
}

// Make grid visible for demonstration
.row .show-grid,
.show-grid .row {
  [class*='col-'] {
    position: relative;
    min-height: $grid-demo-height;

    &::before {
      content: attr(data-grid-example-text);
      display: table-cell;
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0.2rem solid $grid-demo-color;
      background: rgba($grid-demo-color, 0.2);
      text-align: center;
      font-size: 1rem;
      line-height: 1.6;
      padding: 0.8rem 1.6rem;
    }

    i {
      white-space: nowrap;
    }

    @for $i from 1 through length($breakpoints) {
      @media screen and (min-width: nth($sizes, $i)) {
        $mq: nth($breakpoints, $i);

        .marker-#{$mq} {
          color: $grid-demo-color;
        }
      }
    }
  }
}
