ul.dropdown {
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;

  & > li {
    position: relative;
    margin: 0 2.2rem 0 0;

    a {
      font-size: 1rem;
      padding: 1.6rem 0;
      margin: 0;
      color: $color__black--60;
      display: inline-block;
      text-decoration: none;

      .label {
        font-size: inherit;
        margin: 0;

        i {
          svg {
            fill: $color__black--60;
            transition: transform 300ms ease-in-out;
          }
        }

        strong {
          font-size: 1.4rem;
          font-weight: 400;
          color: $color__black--60;
        }

        &.label-icon {
          &[icon-pos='before-absolute'] {
            padding-left: 2rem;

            i {
              left: -0.6em;
            }
          }

          &[icon-pos='after-absolute'] {
            padding-right: 2rem;

            i {
              right: -0.6em;
            }
          }
        }
      }

      &:hover {
        color: $color__black;
        opacity: 1;

        .label {
          strong {
            color: $color__black;
          }

          i {
            svg {
              fill: $color__black;
            }
          }
        }
      }

      &[disabled] {
        strong {
          color: rgba($color__black, 0.4);
        }
      }
    }

    & > a {
      &::after {
        max-height: 0;
        background: $color__black;
        bottom: 0;
      }
    }

    ul {
      position: absolute;
      top: 4rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      max-height: 0;
      transition: all 200ms ease-in-out;
      background: $color__white;
      min-width: 5rem;
      list-style: none;
      padding: 0;
      margin: 0;
      overflow-x: hidden;
      overflow-y: auto;

      li {
        min-width: 4rem;
        background: $color__white;
        opacity: 0;
        height: 100%;
        max-height: 0;
        transition: all 100ms ease-in-out;
        margin: 0;
        position: relative;

        & > a {
          display: block;
          padding: 1.2rem 1.6rem;

          span.label {
            justify-content: flex-start;

            strong {
              font-size: 1.7rem;
            }
          }

          &::after {
            max-height: 0;
          }
        }

        &:first-child {
          padding-top: 0.8rem;
        }

        &:last-child {
          padding-bottom: 0.8rem;
        }

        &:hover {
          & > a {
            background-color: $color__black--10;
          }
        }
      }

      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color__white;
      }
    }

    &.current {
      & > ul {
        z-index: 2000;
        max-height: 32rem;
        box-shadow: 0 0 0.3rem 0 rgba($color__black, 0.4);

        &::before {
          content: '';
          position: absolute;
          top: -0.1rem;
          right: 0;
          left: 0;
          width: 100%;
          border-top: 0.1rem solid $color__black;
        }

        li {
          opacity: 1;
          max-height: 100%;
          height: auto;
        }
      }

      &::before {
        content: '';
        display: block;
        position: fixed;
        z-index: 100;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      & > a {
        & > .label {
          i {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    &:active,
    &.active,
    &.active {
      & > a strong {
        text-decoration: none;
        color: $color__black;
      }
    }
  }

  li.has-sub {
    .icon.toggle-sub {
      right: 1rem;

      svg {
        width: 1.6rem;
        height: 1.6rem;
        transform: rotate(90deg);
        margin-top: 0.6rem;
      }
    }
  }

  & > li.has-sub {
    & > .icon.toggle-sub {
      display: none;
    }
  }
}
