*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;

  // Is needed for a "sticky footer"
  > .content {
    min-height: calc(100vh - 25rem); // REM is sum of header (6.4rem) + footer + footer margin (10.6rem) - top-margin of first row (1.2rem).
  }
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100vh;
}

#back-to-top {
  padding: 6.4rem 0 0;
  display: block;
}

html:not(.f-menu-active) {
  body {
    &.viewport-freeze {
      width: 100vw;
      max-width: 100%;
      height: 100vh;
      max-height: 100%;
      overflow: hidden;
    }

    &::after {
      content: '';
      display: block;
      position: fixed;
      z-index: 400;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-height: 0;
      opacity: 0;
      background: rgba($color__black, 0.6);
      transition: opacity 300ms ease-in-out;
    }

    &.overlay {
      &::after {
        @media screen and (min-width: #{$mq-l}) {
          max-height: 100%;
          opacity: 1;
        }
      }
    }
  }
}

body {
  // Importing CSS Breakpoints Into JavaScript
  // https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
  &::before {
    content: 'mobile';
    // Prevent from displaying.
    display: none;
  }

  @media screen and (min-width: #{$mq-m}) {
    &::before {
      content: 'tablet';
    }
  }

  @media screen and (min-width: #{$mq-l}) {
    &::before {
      content: 'desktop';
    }
  }
}

@media screen and (min-width: $mq-m) {
  body {
    &.viewport-freeze {
      overflow-y: auto;
    }
  }
}

@media screen and (min-width: $mq-l) {
  body {
    &.scroll-up {
      #back-to-top {
        transition: padding 300ms ease-in-out;
      }
    }
  }
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}
