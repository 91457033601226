.label {
  height: initial;
  display: inline-block;
  position: relative;

  i {
    position: relative;
    width: auto;
  }

  strong {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    height: initial;
    font-weight: $base--font-weight-regular;
  }

  &.label--align-left {
    strong {
      text-align: left;
    }
  }

  &.label--align-right {
    strong {
      text-align: right;
    }
  }

  &[icon-pos='before'] {
    direction: ltr;

    strong {
      padding-left: 1rem;
    }
  }

  &[icon-pos='after'] {
    direction: rtl;

    strong {
      direction: ltr;
      padding-right: 1rem;
    }
  }

  &[icon-pos='before-absolute'] {
    padding-left: 3em;
    width: 100%;

    i {
      position: absolute;
      left: 0.95em;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &[icon-pos='after-absolute'] {
    padding-right: 3em;
    width: 100%;

    i {
      position: absolute;
      right: 0.95em;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.bold {
    strong {
      font-weight: bold;
    }
  }

  &.align-left {
    justify-content: flex-start;
  }

  &.align-right {
    justify-content: flex-end;
  }
}
