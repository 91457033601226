.slider {
  @include clearfix;
  position: relative;
  margin: 0 auto;
  width: 100%;

  .flickity-viewport {
    position: absolute;
    width: 100%;
  }

  .slider__inner {
    @include full;
    position: relative;
    height: 100%;
  }

  .slider__items {
    @include clearfix;
    //padding-bottom: (675/1200)*100%; //aspect ratio
    height: 100vh;
    //height: calc(100vh - 3rem);
    //margin-bottom: 3rem;
  }

  .slider__item {
    width: 100%;
    height: 100%;

    &.dark p,
    &.dark h2 {
      color: $color__white;
    }

    &.bright p,
    &.bright h2 {
      color: $color__primary--default;
    }
  }

  .flickity-page-dots {
    display: none;
  }

  .slider__content {
    position: absolute;
    top: calc(50% + 2rem);
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-height: 50%;
    max-width: 800px;
    width: 60%;
    height: 60%;
    text-align: center;

    a {
      max-width: 600px;
      display: block;

      h2 {
        text-align: left;
        margin-top: 0;
        font-size: 3.2rem;
        line-height: 1em;
        color: $color__black;
        margin-bottom: 0.4rem;
        //text-shadow: 0 0 4px rgba(255, 255, 0255, 0.55);
        font-weight: bold;
        font-family: Akkurat, Helvetica, Arial, sans-serif;
      }

      h3 {
        display: none;
      }

      p {
        text-align: left;
        font-weight: 300;
        font-style: normal;
        color: $color__black;
        font-size: 3rem;
        line-height: 1.1em;
        //text-shadow: 0 0 4px rgba(255, 255, 255, 0.55);
        padding-bottom: 2rem;
        font-family: 'lexicon_no1', Times, serif;
      }

      &:hover {
        &::after {
          display: none;
        }

        &::before {
          display: none;
        }
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: -3em;
      right: -2.2em;
      bottom: -3em;
      left: -2.2em;
      border: 5em solid $color__secondary--default;
    }
  }

  .slider__image-container {
    width: 100%;
    height: 100%;
    position: relative;

    figure,
    picture,
    .video {
      width: auto;
      height: 100%;

      video {
        width: auto;
        padding-bottom: 0;
      }
    }
  }

  img,
  video {
    transition: opacity 0.4s;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    z-index: -100;
    object-fit: cover;
    // Polyfill-Notation for object-fit-images (IE11)
    font-family: 'object-fit: cover;';

    &.flickity-lazyloaded,
    &.flickity-lazyerror {
      opacity: 1;
      background-image: none;
    }

    // IE fix
    &[data-flickity-lazyload] {
      width: auto;
    }
  }

  // Prev/Next navigation
  .flickity-prev-next-button {
    display: none;

    .arrow {
      fill: $color__white;
    }

    &:focus {
      box-shadow: 0 0 0.2rem 0.2rem #09f;
    }
  }

  .slider__nav-item {
    width: 2.4rem;
    height: 2.4rem;
    clear: none;
    display: inline-block;
    margin: 0 0.6rem;
    background-color: $color__primary--default;
    border: 0.6rem solid $color__white;
    border-radius: 0.4rem;
  }

  // Dots navigation
  //pageDots: false;

  .flickity-page-dots {
    bottom: -4rem;
  }

  .dot {
    width: 2rem;
    height: 2rem;
    opacity: 1;
    background: transparent;
    border: 0.2rem solid $color__black;
    position: relative;
    margin: 0 0.4rem;

    &::before {
      display: block;
      content: '';
      width: 0.6rem;
      height: 0.6rem;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      border-radius: 100%;
    }

    &:hover {
      background-color: $color__black--10;
    }

    &.is-selected::before {
      background-color: $color__black;
    }
  }

  &[min-width ~='#{$mq-s}'] {
    slider_items {
      height: 100%;
    }

    .slider__content {
      max-width: 80rem;
      width: 70%;

      h2 {
        font-size: 5.2rem;
        padding-bottom: 1rem;
      }

      p {
        font-size: 4.6rem;
        line-height: 1;
        padding-bottom: 4rem;
      }

      &::before {
        top: -4.4em;
        right: -4.8em;
        bottom: -4.4em;
        left: -4.8em;
        border-width: 8em;
      }
    }
  }

  &[min-width ~='#{$mq-m}'] {
    slider_items {
      height: 100%;
    }

    .slider__content {
      max-width: 80rem;
      width: 70%;

      h2 {
        font-size: 6.2rem;
        padding-bottom: 1rem;
      }

      p {
        font-size: 5.6rem;
        line-height: 1;
        padding-bottom: 4rem;
      }

      &::before {
        top: -5.4em;
        right: -5.8em;
        bottom: -5.4em;
        left: -5.8em;
        border-width: 9.4em;
      }
    }
  }

  &[min-width ~='#{$mq-l}'] {
    .slider__content {
      max-width: 80rem;
      width: 100%;

      h2 {
        font-size: 6.2rem;
        padding-bottom: 1rem;
      }

      p {
        font-size: 5.6rem;
        line-height: 1;
        padding-bottom: 4rem;
      }

      &::before {
        top: -6.4em;
        right: -6.8em;
        bottom: -6.4em;
        left: -6.8em;
        border-width: 10em;
      }
    }
  }

  &[min-width ~='#{$mq-l}'] {
    .slider__content {
      max-width: 92rem;

      h2 {
        font-size: 6.8rem;
        padding-bottom: 1rem;
      }

      p {
        font-size: 6.2rem;
        line-height: 1;
        padding-bottom: 4rem;
      }

      &::before {
        top: -6em;
        right: -6.6em;
        bottom: -6em;
        left: -6.6em;
        border-width: 10em;
      }
    }
  }
}
