.video-box {
  width: 100%;
  height: 100%;
  line-height: 0;
  position: relative;
  background: $color__black;

  video {
    width: 100%;
    opacity: 1;
    transition: opacity 300ms ease-in-out;
  }

  .video-startstop {
    position: absolute;
    z-index: 100;
    top: 15%;
    right: 0;
    bottom: 15%;
    left: 0;
    width: 100%;
    height: 70%;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    background: transparent url('../images/icons/default/video-play.svg') no-repeat center center;
    background-size: 6rem 6rem;

    &:hover {
      opacity: 0.8;

      & + video {
        opacity: 0.9;
      }
    }
  }

  &.on {
    .video-startstop {
      background-image: url('../images/icons/default/video-pause.svg');
    }
  }
}
