.section-line {
  hr {
    display: block;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 0.1rem solid $color__black;
    margin: 1rem 0.4rem;
  }
}

@media screen and (min-width: $mq-s) {
  .section-line.sm-only {
    display: none;
  }
}
