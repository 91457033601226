[role='button'] {
  position: relative;
  display: inline-block;
  padding: 0;
  border: 0;
  color: $color__black;
  text-decoration: none;
  background: transparent;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &:focus {
    box-shadow: 0 0 0.2rem 0.4rem $color__white, 0 0 0.1rem 0.5rem $color__black--80;
  }

  &:disabled,
  &.disabled {
    cursor: auto;
  }

  &.btn-text-link-style {
    padding: 1.9rem;
    text-decoration: underline;
  }

  .label {
    font-size: inherit;
    line-height: inherit;
    text-decoration: inherit; // Is needed to deaktivate the style from typography.
    height: initial;

    strong {
      text-decoration: inherit; // Is needed to deaktivate the style from typography.
    }
  }
}
