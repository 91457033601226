.breadcrumb {
  @include clearfix;
  display: block;
  list-style: none;
  padding: 0;
  margin: 0 0 1.2rem;
}

.breadcrumb__item {
  display: inline-block;
  padding: 0.8rem 0;

  a {
    display: inline-block;
    color: $color__black--20;

    &:hover {
      color: $color__black;
    }
  }

  &::after {
    content: '|';
    margin: 0 1.2rem;
    color: $color__black--20;
  }

  &:last-child {
    &::after {
      content: '';
      display: none;
    }

    a {
      color: $color__primary--default;
    }
  }
}

// STATES
.breadcrumb__item--active {
  color: $color__primary--default;
}

@media screen and (min-width: $mq-m) {
  .breadcrumb {
    margin-bottom: 2.4rem;
  }
}
