.footer__language {
  position: relative;
  z-index: 101;

  ul {
    display: inline-block;
    list-style: none;
    margin: 0.8rem 0;
    padding-left: 0;

    li {
      display: inline-block;
      padding-right: 1.6rem;
      margin: 0;

      a {
        position: relative;
        padding: 0;
        font-family: Akkurat, Helvetica, Arial, sans-serif;
        font-weight: 300;
        font-size: 1.4rem;

        span {
          text-transform: uppercase;
          display: inline-block;
          margin: 0;
          padding: 0;
          font-weight: 300;
          color: $color__white;
        }

        &.language-selected {
          span {
            text-decoration: underline;
          }
        }

        &.active,
        &.language-selected,
        &:hover,
        &:not(.btn):not(.ignore-href-style):hover {
          &::before {
            display: none;
            height: 0;
          }
        }
      }

      // &::after {
      //   content: '/';
      //   display: inline-block;
      //   padding-left: 0.4rem;
      // }

      &:last-child::after {
        display: none;
      }

      &::before {
        display: none;
      }
    }
  }

  @media screen and (min-width: $mq-m) {
    ul {
      font-size: 1.4rem;
    }
  }
}
