.stage {
  position: relative;
  margin: 0 0 20vh;
  width: 100%;
  height: auto;

  .stage__image-container {
    max-height: 80vh;
    overflow: hidden;

    figure {
      height: 80vh;

      picture {
        height: 100%;

        img {
          height: 100%;
          object-fit: cover;
          // Polyfill-Notation for object-fit-images (IE11)
          font-family: 'object-fit: cover;';
          display: block;
        }
      }
    }
  }

  .stage__content {
    position: absolute;
    z-index: 10;
    bottom: -2.6rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    width: auto;
    display: inline-block;
    font-size: 3.8rem;

    .stage-title {
      margin: auto;
      padding: 0;
      margin-bottom: 0;
      text-align: center;
      position: relative;
      z-index: 140;
      font-size: 2rem;
      line-height: 1.1;
    }

    .stage-subtitle {
      text-align: center;
      margin: auto;
      padding: 0;
      position: relative;
      z-index: 120;
      font-size: 2rem;
      line-height: 1.1;
    }

    br {
      display: none;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      right: -0.3rem;
      top: 50%;
      left: -0.3rem;
      transform: translateY(-50%);
      background: $color__secondary--default;
      width: calc(100% + 0.6rem);
      height: 0.6em;
      display: inline-block;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: -2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 300%;
      height: 300%;
      background: radial-gradient(ellipse at center, rgba($color__white, 0.7) 0%, rgba($color__white, 0) 70%);

      // background: linear-gradient(to bottom, rgba($color__white, 0) 0%,rgba($color__white, 0.4) 50%,rgba($color__white, 0) 100%);
    }
  }
}

@media screen and (min-width: $mq-s) {
  .stage {
    .stage__content {
      bottom: -4rem;

      .stage-title {
        font-size: 2.8rem;
      }

      .stage-subtitle {
        font-size: 2.8rem;
      }

      &::before {
        height: 1em;
      }
    }
  }
}

@media screen and (min-width: $mq-m) {
  .stage {
    .stage__content {
      .stage-title {
        font-size: 3.8rem;
      }

      .stage-subtitle {
        font-size: 3.8rem;
      }
    }
  }
}
