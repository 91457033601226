label {
  &[role='radio'],
  &[role='checkbox'] {
    .label {
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 4.2rem;
      line-height: 2.2rem;

      strong {
        width: 90%;
        padding: 0.4rem 0;
        text-align: left;
        order: 2;
        font-size: 1.5rem;
        line-height: 2.2rem;
        color: $color__black;
      }
    }

    // OWN RADIO + CHECKBOX
    // Reset Default View
    input[type='radio'] {
      visibility: hidden;
      position: absolute;

      & ~ span.label {
        cursor: pointer;
        color: $color__black--60;

        strong {
          color: $color__black--60;
          margin-left: 3rem;
        }
      }

      & + span.label::after {
        content: '';
        position: absolute;
        z-index: 10;
        top: 0.3rem;
        left: 0;
        min-height: 2.4rem;
        min-width: 2.4rem;
        width: 1.4rem;
        border: 0.2rem solid $color__black--60;
        border-radius: 50%;
        display: inline-block;
      }

      &:checked + span.label {
        color: $color__black;

        strong {
          color: $color__black;
        }

        &::after {
          background-color: $color__black;
          box-shadow: inset 0 0 0 #e4e4e4, inset 0 0 0 $color__black--80, inset 0 0 0 0.4rem $color__white;
        }
      }
    }

    input[type='checkbox'] {
      visibility: hidden;
      position: absolute;

      & ~ span.label {
        cursor: pointer;

        strong {
          margin-left: 3.2rem;
        }
      }

      & + span.label {
        position: relative;
        top: 0;
        left: 0;
        color: $color__black--60;

        strong {
          color: $color__black--60;
        }

        &::before {
          content: '';
          position: absolute;
          z-index: 100;
          top: 0.1rem;
          left: 0;
          min-width: 2.4rem;
          min-height: 2.4rem;
          order: 1;
          display: inline-block;
        }

        &::after {
          content: '';
          position: absolute;
          z-index: 10;
          top: 0.1rem;
          left: 0;
          min-width: 2.4rem;
          min-height: 2.4rem;
          border: 0.2rem solid $color__black--60;
          border-radius: 0.2rem;
          order: 1;
          display: inline-block;
        }
      }
    }

    input[type='radio'],
    input[type='checkbox'] {
      &[disabled] ~ span.label {
        color: $color__black--20;
        cursor: default;

        strong {
          color: $color__black--40;
        }

        &::after {
          border-color: $color__black--40;
        }
      }

      &:not([disabled]) {
        &:checked + span.label {
          color: $color__black;
          cursor: default;

          strong {
            color: $color__black;
          }

          &::after {
            border-color: $color__black;
          }

          &::before {
            border: 0;
            background: no-repeat center center;
            background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="' + $color__black + '" d="M12.667 24.533l-7.6-7.6 1.867-1.867 5.733 5.733 12.4-12.4 1.867 1.867z"></path></svg>');
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }
    }

    &[data-valid-label] {
      &::before {
        opacity: 0;
      }
    }

    &[data-error-label] {
      &::before {
        opacity: 0;
      }
    }
  }

  &[role='radio'] {
    margin: 1.1rem 0;

    .label {
      min-height: 0;
    }

    input[type='radio'] {
      & ~ span.label {
        strong {
          margin-left: 3.2rem;
        }
      }

      & + span.label::after {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
