.slider-partner {
  @include clearfix;
  position: relative;
  margin: 0 auto;
  padding-bottom: 4rem;
  width: 100%;

  .flickity-page-dots {
    li.dot {
      border: none;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;

      &::before {
        display: none;
      }

      svg {
        color: $color__white;
      }

      &:hover,
      &.is-selected {
        background: none;

        svg {
          color: $color__secondary--default;
        }
      }
    }
  }

  .slider__item {
    width: 100%;
    height: 100%;

    &.dark,
    &.dark h1 {
      color: $color__white;
    }

    &.bright,
    &.bright h1 {
      color: $color__primary--default;
    }
  }

  .slider__inner {
    @include full;
    position: relative;
    height: 100%;
  }

  .slider__items {
    @include clearfix;
    height: (675/1200)*100vw * 2; //aspect ratio
    max-height: 40rem;
  }

  .slider__image-container {
    width: 100%;
    height: 60%;
    position: relative;

    figure,
    picture,
    .video {
      width: auto;
      height: 100%;

      video {
        width: auto;
        padding-bottom: 0;
      }
    }
  }

  .slider__content {
    text-align: center;
    background: $color__secondary--default;
    padding: 0 3rem;
    display: flex;
    align-items: center;
    max-width: 60rem;
    min-width: 12rem;
    position: absolute;
    height: 40%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;

    .slider__content-wrap {
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0;
      padding-left: 0;
      margin-left: auto;
      margin-right: auto;

      a {
        display: block;
        padding: 0;
        margin: 0;

        h2 {
          font-size: 1.2rem;
          line-height: 1.4;
          color: $color__primary--default;
          margin-top: 0;
          margin-bottom: 0.2em;
          font-family: Akkurat, Helvetica, Arial, sans-serif;
          font-weight: bold;
          text-align: center;
          position: relative;
          z-index: 10;

          &::before {
            content: '';
            transition: top, 200ms;
            position: absolute;
            z-index: -1;
            top: 50%;
            right: 0;
            left: 0;
            border-bottom: 0 solid $color__white;
            transform: translateY(-50%);
            width: 100%;
            height: 0;
          }
        }

        h3 {
          font-size: 0.85rem;
          line-height: 1.4;
          color: $color__primary--default;
          margin-top: 0;
          margin-bottom: 0.2em;
          font-family: Akkurat, Helvetica, Arial, sans-serif;
          font-weight: bold;
          position: absolute;
          top: -1.6em;
          right: 0;
          left: 0;
          width: 100%;
          text-align: center;
        }

        p {
          text-align: center;
          margin-left: 5%;
          margin-right: 5%;
          margin-top: 0;
          font-family: Akkurat, Helvetica, Arial, sans-serif;
          font-weight: 300;
          font-style: normal;
          font-size: 1rem;
          line-height: 1.4;
          color: $color__black;
          margin-bottom: 0.8rem;

          strong {
            color: $color__black;
          }
        }

        svg {
          display: block;
          width: 100%;
          color: $color__secondary--default;
          margin-top: 1rem;
        }

        &:hover {
          h2 {
            display: inline;

            &::after {
              content: '';
              position: absolute;
              z-index: -1;
              left: 0;
              bottom: -0.4rem;
              width: 100%;
              height: 0.1rem;
              border-bottom: 1.6em solid $color__white;
            }
          }

          svg {
            color: $color__white;
            //fill: $color__white;
          }

          &::after {
            display: none;
          }
        }

        &:active {
          color: $color__link--active;
        }
      }
    }
  }

  .flickity-slider {
    position: relative;
  }

  // Prev/Next navigation
  .flickity-prev-next-button {
    background-color: transparent;
    border-radius: 0;
    top: 50%;
    height: 102%;
    width: 5rem;
    border-top: 0.2rem solid $color__white;
    border-bottom: 0.2rem solid $color__white;
    cursor: pointer;
  }

  .flickity-prev-next-button.previous {
    left: 0;
    color: transparent;

    svg {
      color: transparent;
    }

    &:hover,
    &:active {
      svg {
        color: $color__secondary--default;
      }
    }
  }

  .flickity-prev-next-button.next {
    right: 0;

    svg {
      color: transparent;
    }

    &:hover,
    &:active {
      svg {
        color: $color__secondary--default;
      }
    }
  }

  &[min-width ~='500px'] {
    //overflow: hidden;

    .flickity-slider {
      position: absolute;
    }

    .flickity-viewport {
      position: absolute;
      width: 100%;
    }

    .slider__items {
      @include clearfix;
      height: (500/1200)*100vw; //aspect ratio
      max-height: 40rem;
    }

    .slider__content {
      position: absolute;
      top: 0;
      width: 40%;
      height: 100%;
      text-align: center;
      background: $color__secondary--default;
      padding: 0 6.6rem;
      display: flex;
      align-items: center;
      max-width: 60rem;
      min-width: 12rem;
    }

    .slider__image-container {
      left: 20%;
      width: 100%;
      height: 100%;
    }

    // Prev/Next navigation
    .flickity-prev-next-button {
      background-color: $color__white;
      width: 5rem;
    }

    .flickity-prev-next-button.previous {
      left: -5rem;
      background: $color__white;
      color: $color__white;

      svg {
        color: $color__white;
      }

      &:hover,
      &:active {
        svg {
          color: $color__secondary--default;
        }
      }
    }

    .flickity-prev-next-button.next {
      right: -5rem;
      background: $color__white;

      svg {
        color: $color__white;
      }

      &:hover,
      &:active {
        svg {
          color: $color__secondary--default;
        }
      }
    }
  }

  &[min-width ~='600px'] {
    .slider__content {
      padding: 0 4.4rem;

      .slider__content-wrap a {
        h2 {
          font-size: 1.6rem;
          line-height: 1.4;
        }

        p {
          font-size: 1.2rem;
          line-height: 1.4;
          margin-left: 10%;
          margin-right: 10%;
        }
      }
    }
  }

  &[min-width ~='800px'] {
    .slider__items {
      @include clearfix;
      height: (675/1200)*100vw; //aspect ratio
      max-height: 40rem;
    }

    .slider__content {
      .slider__content-wrap a {
        h2 {
          font-size: 2.2rem;
          line-height: 1.4;
        }

        p {
          font-size: 1.6rem;
          line-height: 1.4;
        }
      }
    }
  }

  img,
  video {
    transition: opacity 0.4s;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    z-index: -100;
    object-fit: cover;
    // Polyfill-Notation for object-fit-images (IE11)
    font-family: 'object-fit: cover;';

    &.flickity-lazyloaded,
    &.flickity-lazyerror {
      opacity: 1;
      background-image: none;
    }

    // IE fix
    &[data-flickity-lazyload] {
      width: auto;
    }
  }

  .slider__nav-item {
    width: 2.4rem;
    height: 2.4rem;
    clear: none;
    display: inline-block;
    margin: 0 0.6rem;
    background-color: $color__primary--default;
    border: 0.6rem solid $color__white;
    border-radius: 0.4rem;
  }

  // Dots navigation
  //pageDots: false;

  .flickity-page-dots {
    bottom: -4rem;
  }

  .dot {
    width: 2rem;
    height: 2rem;
    opacity: 1;
    background: transparent;
    border: 0.2rem solid $color__black;
    position: relative;
    margin: 0 0.4rem;

    &::before {
      display: block;
      content: '';
      width: 0.6rem;
      height: 0.6rem;
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      border-radius: 100%;
    }

    &:hover {
      background-color: $color__black--10;
    }

    &.is-selected::before {
      background-color: $color__black;
    }
  }
}
