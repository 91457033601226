.news-list-titlestory {
  position: relative;

  figure {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  header {
    padding-right: 2em;

    .date {
      padding: 0 0 0.6rem;
      font-size: 1.4rem;
    }

    .label {
      display: inline;

      h2 {
        font-size: 1.6rem;
        font-family: 'lexicon_no1', Times, serif;
        font-weight: bold;
        display: inline;
        position: relative;
        top: -0.2em;
      }
    }

    .author {
      font-size: 1.4rem;
      font-family: 'lexicon_no1', Times, serif;
      font-weight: bold;
      padding: 0.6em 0 0.6rem;
    }

    .source {
      a {
        font-size: 1.4rem;
        font-family: 'lexicon_no1', Times, serif;
        color: $color__black--20;

        svg {
          color: $color__white;
          fill: $color__black--20;
        }

        &:hover,
        &:active {
          &::before {
            display: none;
            height: 0;
          }

          .icon {
            color: $color__secondary--default;
          }
        }
      }
    }
  }

  @media screen and (max-width: calc($mq-m - 1px)) {
    header {
      position: inherit;
      padding-top: 1rem;
      padding-bottom: 1.6rem;

      .source {
        display: none;
      }

      .author {
        position: absolute;
        bottom: -0.8rem;
        padding: 0;
      }
    }
  }

  @media screen and (min-width: $mq-m) {
    figure {
      margin-bottom: 2rem;
    }
  }

  section {
    header p {
      font-size: 1.2rem;
      font-family: 'lexicon_no1', Times, serif;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 1.4em;
    }

    @media screen and (min-width: $mq-m) {
      header {
        display: none;
      }
    }

    p {
      font-size: 1.6rem;
      font-family: 'lexicon_no1', Times, serif;

      a {
        svg {
          color: $color__white;
        }

        &:hover,
        &:active {
          &::before {
            display: none;
          }

          .icon {
            color: $color__secondary--default;
          }
        }
      }
    }
  }

  @media screen and (min-width: $mq-m) {
    section {
      padding-bottom: 0.8em;
    }
  }
}
