.link {
  &.link-btn {
    padding: 0.6rem 3.2rem;
    font-size: 1.3rem;
    line-height: 2rem;
    text-decoration: none;

    @media screen and (min-width: $mq-s) {
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
      font-size: 1.5rem;
      line-height: 2.2rem;
    }

    @media screen and (min-width: $mq-m) {
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
    }

    @media screen and (min-width: $mq-l) {
      padding-top: 1.7rem;
      padding-bottom: 1.7rem;
      font-size: 1.7rem;
      line-height: 2.4rem;
    }

    .label {
      display: table;
      margin: 0 auto;
      font-size: inherit;
      line-height: inherit;

      .icon {
        display: table-cell;
        overflow: hidden;
      }

      strong {
        display: table-cell;
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
}
