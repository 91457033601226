.icon {
  display: inline-block;
  text-align: center;
  vertical-align: middle;

  svg {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    width: 3rem;
    height: 3rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    fill: currentColor;
    pointer-events: none;
  }

  use {
    pointer-events: none;
  }

  &.icon-xxs {
    svg {
      width: 0.7rem;
      height: 0.7rem;
    }
  }

  &.icon-xs {
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &.icon-s {
    svg {
      width: 2.2rem;
      height: 2.2rem;
    }
  }

  &.icon-l {
    svg {
      width: 3.7rem;
      height: 3.7rem;
    }
  }

  &.icon-xl {
    svg {
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  &.icon--hieronymus-footer-logo {
    &.icon-xxl {

      display: flex;
      margin-top: $grid-gap;
      padding-bottom: 30px;
      
      svg {
        width: 12rem;
        height: 12rem;
        margin: auto;
      }
    }
  }

  &.icon-xxl {
    svg {
      width: 5.2rem;
      height: 5.2rem;
    }
  }
}

// sprite with all icons as symbol
//stylelint-disable declaration-no-important
.icon__sprite {
  position: fixed !important;
  left: -9999rem !important;
  width: 0.1rem !important;
  overflow: hidden !important;
}
//stylelint-enable declaration-no-important


// inlinesvg support reset
// ----------------------------------------------------------
.inlinesvg .icon {
  background-repeat: repeat;
  background-size: auto auto;
}
