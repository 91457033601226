.news-list {
  margin: 0 0 4rem;

  .article {
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    figure {
      //width: 100%;
      //height: 100%;
      max-height: 24rem;
      margin: 0 0 2rem;
      overflow: hidden;

      picture {
        display: block;
        width: 100%;
        height: 100%;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          // Polyfill-Notation for object-fit-images (IE11)
          font-family: 'object-fit: cover;';
          object-position: 50% 50%;
        }
      }
    }

    header {
      .date {
        padding: 0 0 0.6rem;
        font-size: 1.4rem;
        font-family: Akkurat, Helvetica, Arial, sans-serif;
        font-weight: bold;
      }
    }

    section {
      min-height: 16rem;
      padding-right: 1.6em;

      h3 {
        font-size: 2.4rem;
        line-height: 1.4;
        font-family: 'lexicon_no1', Times, serif;
        font-weight: bold;
        margin: 0 0 0.2em;
      }

      p {
        font-size: 1.6rem;
        line-height: 1.4;
        font-family: 'lexicon_no1', Times, serif;

        svg {
          color: $color__white;
        }
      }
    }

    footer {
      padding-bottom: 1rem;
      border-bottom: 0.1rem solid $color__black;

      .author {
        font-size: 1.4rem;
        font-family: 'lexicon_no1', Times, serif;
        font-weight: bold;
        padding: 0.2em 0 0.6rem;
      }
    }

    a {
      &::before {
        display: none;
      }

      section {
        h3 {
          position: relative;
          display: inline;
        }
      }

      &:hover {
        &::after {
          display: none;
        }

        section {
          h3 {
            &::after {
              content: '';
              position: absolute;
              z-index: -1;
              left: 0;
              bottom: -0.4rem;
              width: 100%;
              height: 0.1rem;
              border-bottom: 1.4em solid $color__secondary--default;
            }
          }

          p {
            .icon {
              color: $color__secondary--default;
            }
          }
        }
      }
    }
  }

  nav {
    display: block;
    width: 100%;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;

      li {
        display: inline-block;
        font-size: 2rem;

        a {
          display: inline-block;
          position: relative;
          top: -4px;

          svg {
            color: $color__white;
          }

          &:hover,
          &:active {
            &::before {
              display: none;
            }

            .icon {
              color: $color__secondary--default;
            }
          }
        }

        span {
          font-family: Akkurat, Helvetica, Arial, sans-serif;
          font-weight: bold;
          font-size: 1.6rem;
          min-width: 0.6em;
          display: inline-block;
        }

        &::before {
          display: none;
        }
      }
    }
  }

  .show-more {
    font-family: Akkurat, Helvetica, Arial, sans-serif;
    font-weight: bold;
    cursor: pointer;
  }
}
