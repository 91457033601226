label {
  &[role='input'],
  &[role='text'],
  &[role='password'],
  &[role='email'],
  &[role='number'],
  &[role='url'],
  &[role='tel'],
  &[role='search'] {
    min-height: 7.2rem;

    .label {
      height: 0;
      line-height: 0;
      padding: 0;
      overflow: hidden;

      strong {
        padding: 1.1rem 1.6rem 0;
        line-height: 1.4;
        text-align: left;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 300ms ease-in-out, color 300ms ease-in-out;
      }
    }

    input {
      outline: 0;
      background-color: transparent;
      color: $color__black;
      border-radius: 0;
      font-size: 1.7rem;
      line-height: 2.4rem;
      font-family: $base--font-family;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      padding: 2.3rem 1rem 2.3rem 1.6rem;
      position: relative;
      z-index: 100;
      transition: height 300ms ease-in-out, border-color 300ms ease-in-out;
      margin-top: -0.2rem; // To fix the position since label is move the content down.

      &:focus:valid {
        outline: 0;
      }
    }

    &.has-content {
      .label {
        height: auto;

        strong {
          opacity: 1;
        }
      }

      input {
        margin-top: 0;
        padding: 0.4rem 1rem 1.1rem 1.6rem;
      }
    }

    &.is-active {
      & > input,
      & > .label,
      & > .text-container {
        border-color: $color__black;
      }

      & > .label {
        strong {
          color: $color__black;
        }
      }
    }
  }

  &[role='time'],
  &[role='date'],
  &[role='datetime'],
  &[role='month'],
  &[role='week'] {
    min-height: 7.2rem;

    .label {
      line-height: 0;
      padding: 0;
      overflow: hidden;

      strong {
        padding: 1.1rem 1.6rem 0;
        line-height: 1.4;
        text-align: left;
        width: 100%;
        height: 100%;
        transition: opacity 300ms ease-in-out, color 300ms ease-in-out;
      }
    }

    input {
      outline: 0;
      background-color: transparent;
      color: $color__black;
      border-radius: 0;
      font-size: 1.7rem;
      line-height: 2.4rem;
      font-family: $base--font-family;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      padding: 0.4rem 1rem 1.1rem 1.6rem;
      position: relative;
      z-index: 100;
      transition: height 300ms ease-in-out, border-color 300ms ease-in-out;
      margin-top: -0.2rem; // To fix the position since label is move the content down.
      align-items: center;
      display: -webkit-inline-flex; // stylelint-disable-line value-no-vendor-prefix

      &::-webkit-datetime-edit-week-field {
        color: $color__black--60;
      }

      &::-webkit-datetime-edit-month-field {
        color: $color__black--60;
      }

      &::-webkit-datetime-edit-day-field {
        color: $color__black--60;
      }

      &::-webkit-datetime-edit-year-field {
        color: $color__black--60;
      }

      &::-webkit-clear-button {
        font-size: 1.6rem;
        height: 3rem;
        margin-right: 0;
      }

      &::-webkit-inner-spin-button {
        opacity: 0;
      }

      &::-webkit-calendar-picker-indicator {
        color: transparent;
        background: no-repeat center center;
        background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="' + $color__black + '" d="M15.333 26h1.333v-5.333h5.333v-1.333h-5.333v-5.333h-1.333v5.333h-5.333v1.333h5.333z"></path><path fill="' + $color__black + '" d="M25.333 2.667v-1.333h-1.333v1.333h-16v-1.333h-1.333v1.333h-5.333v28h29.333v-28h-5.333zM29.333 29.333h-26.667v-18.667h26.667v18.667zM29.333 9.333h-26.667v-5.333h4v1.333h1.333v-1.333h16v1.333h1.333v-1.333h4v5.333z"></path></svg>');
        width: 1.6rem;
        height: 1.6rem;
        transform: translateX(-1rem);
      }

      &:focus:valid {
        outline: 0;
      }
    }

    &.has-content {
      [type='time'],
      [type='date'],
      [type='datetime'],
      [type='month'],
      [type='week'] {
        input {
          padding: 0.8rem 1rem 1.6rem 1.6rem;
        }

        &::-webkit-datetime-edit-week-field {
          color: $color__black;
        }

        &::-webkit-datetime-edit-month-field {
          color: $color__black;
        }

        &::-webkit-datetime-edit-day-field {
          color: $color__black;
        }

        &::-webkit-datetime-edit-year-field {
          color: $color__black;
        }

        &::-webkit-calendar-picker-indicator {
          background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path fill="' + $color__black + '" d="M13.333 26.267l9.867-9.733-1.067-1.067-8.8 8.933-3.467-3.6-1.067 1.067z"></path><path fill="' + $color__black + '" d="M25.333 2.667v-1.333h-1.333v1.333h-16v-1.333h-1.333v1.333h-5.333v28h29.333v-28h-5.333zM29.333 29.333h-26.667v-18.667h26.667v18.667zM2.667 9.333v-5.333h4v1.333h1.333v-1.333h16v1.333h1.333v-1.333h4v5.333h-26.667z"></path></svg>');
        }
      }
    }

    &.is-active {
      & > input,
      & > .label,
      & > .text-container {
        border-color: $color__black;
      }

      & > .label {
        strong {
          color: $color__black;
        }
      }
    }
  }

  // Sepcial case range
  &[role='range'] {
    .label {
      line-height: 0;
      padding: 0;
      overflow: hidden;

      strong {
        padding: 0.8rem 1.6rem 0;
        line-height: 1.4;
        text-align: left;
        width: 100%;
        height: 100%;
      }
    }

    input {
      outline: 0;
      background-color: transparent;
      color: $color__black;
      border-radius: 0;
      font-size: 1.7rem;
      font-family: $base--font-family;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      margin: 0.8rem 1.6rem 1.6rem 1.6rem;
      position: relative;
      z-index: 100;
      transition: height 300ms ease-in-out, border-color 300ms ease-in-out;
      border: 0;

      &:focus:valid {
        outline: 0;
      }
    }

    &.has-content {
      [type='time'],
      [type='date'],
      [type='datetime'],
      [type='month'],
      [type='week'] {
        input {
          margin: 0.8rem 1rem 1.6rem 1.6rem;
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0.2rem solid $color__black;
    }
  }

  // Special case color
  &[role='color'] {
    .label {
      line-height: 0;
      padding: 0;
      overflow: hidden;

      strong {
        padding: 0.8rem 1.6rem 0;
        line-height: 1.4;
        text-align: left;
        width: 100%;
        height: 100%;
      }
    }

    input {
      outline: 0;
      background-color: transparent;
      color: $color__black;
      border-radius: 0;
      font-size: 1.7rem;
      font-family: $base--font-family;
      box-sizing: border-box;
      width: 6.4rem;
      height: 3.2rem;
      margin: 0 1rem 1.4rem 1.2rem;
      position: relative;
      z-index: 100;
      transition: height 300ms ease-in-out;
      border: 0;

      &:focus:valid {
        outline: 0;
      }
    }

    &.has-content {
      [type='time'],
      [type='date'],
      [type='datetime'],
      [type='month'],
      [type='week'] {
        input {
          margin: 0.8rem 1rem 1.6rem 1.6rem;
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0.2rem solid $color__black;
    }
  }
}
