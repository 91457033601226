.themes-list {
  margin-top: 3rem;
  margin-bottom: 4rem;

  .header__nav-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border: none;

    .header__nav-section-title {
      font-size: 2rem;
      //max-width: 400px;
      text-align: center;
      text-transform: none;
      border: none;
      margin-top: 1rem;
      margin-bottom: 3rem;
      color: $color__white;
    }

    .header__nav-section-item-wide {
      color: $color__black--20;
    }

    .header__nav-section-items a.header__nav-section-item-wide {
      .header__nav-section-item-title-large {
        font-size: 2rem;
        max-width: 300px;
        line-height: 1.4;
        margin-bottom: 2rem;
        //color: $color__black;

        strong {
          color: $color__white;
        }
      }

      &:hover {
        text-decoration: none;

        .header__nav-section-item-readmore {
          text-decoration: underline;
        }
      }

      &:active {
        text-decoration: none;

        .header__nav-section-item-readmore {
          text-decoration: underline;
        }
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: -100%;
    bottom: 0;
    left: -100%;
    width: 300%;
    height: 100%;
    // Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7db9e8+0,1e5799+100
    // background: $color__primary--default-light;
    // background: -moz-linear-gradient(top, $color__primary--default-light 0%, $color__primary--default 100%);
    // background: -webkit-linear-gradient(top, $color__primary--default-light 0%, $color__primary--default 100%);
    // background: linear-gradient(to bottom, $color__primary--default-light 0%, $color__primary--default 100%);
  }
}

// Themes Modal
.themes-list-modal {
  .tingle-modal-box {
    // Themes modal height control...
    height: 80%;
    min-height: 50vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin-top: 0;
    margin-bottom: 0;
  }

  .header__nav-section-items a.header__nav-section-item-wide {
    .header__nav-section-item-title-large {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }

    &:hover {
      text-decoration: none;

      .header__nav-section-item-readmore {
        text-decoration: underline;
      }
    }

    &:active {
      text-decoration: none;

      .header__nav-section-item-readmore {
        text-decoration: underline;
      }
    }
  }

  &[min-width~='700px'] {
    .tingle-modal-box {
      // Themes modal height control...
      height: 70%;
      min-height: 50vh;
    }

  }

  &[min-width~='1000px'] {
    .tingle-modal-box {
      // Themes modal height control...
      height: 50%;
      min-height: 50vh;
    }
  }
}

@media screen and (min-width: $mq-m) {
  .themes {
    .header__nav-section {
      .header__nav-section-title {
        margin-top: 4rem;
      }
    }
  }
}
