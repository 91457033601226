.standard-text {

  span {
    line-height: 1.3;
  }

  header {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      //font-family: Akkurat, Helvetica, Arial, sans-serif;
      //margin: 0;
      font-size: 1.8rem;
      margin-bottom: 0;
      line-height: 1;

      a {
        &:hover,
        &:active {
          &::before {
            display: none;
          }
        }
      }
    }
    p {
      font-family: $base--font-family;
      margin-top: 0;
      font-weight: $base--font-weight-medium;
      margin-bottom: 1.5rem;
      text-rendering: auto;
      font-size: 1.8rem;
    }
  }
  
  section {
    p {
      font-family: $base--font-family;
      font-size: 1.6rem;
      line-height: 1.4;

      a {
        color: $color__hieronymus--default;
        text-decoration: none;
      }
    }
  }

  svg {
    color: transparent;
  }

  a {
    svg {
      color: transparent;
    }

    &:hover,
    &:active {
      svg {
        color: $color__secondary--default;
      }

      header {
        position: relative;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          display: inline;

          &::after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 50%;
            bottom: -0.4rem;
            width: 70%;
            transform: translateX(-50%);
            border-bottom: 1.4em solid $color__secondary--default;
          }
        }
      }
    }
  }

  &.xs {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 2rem;
    }

    section {
      p {
        font-size: 1.6rem;
      }
    }
  }

  &.xxs {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.6rem;
    }

    section {
      p {
        font-size: 1.2rem;
      }
    }
  }

  &.xl {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 2.8rem;
    }

    section {
      p {
        font-size: 2.4rem;
      }
    }
  }

  &.xxl {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 3.2rem;
    }

    section {
      p {
        font-size: 2.8rem;
      }
    }
  }

  &.highlight {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      z-index: 100;
      line-height: 1.4;
      margin-bottom: 1.2rem;
      display: inline-block;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        right: -0.3rem;
        bottom: -0.2em;
        left: -0.3rem;
        background: $color__secondary--default;
        width: calc(100% + 0.6rem);
        height: 1em;
        display: inline-block;
      }
    }
  }

  &.bold {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-weight: bold;
    }
  }

  &.sans-serif {
    header {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-family: Akkurat, Helvetica, Arial, sans-serif;
      }
    }

    section {
      p {
        font-family: Akkurat, Helvetica, Arial, sans-serif;
      }
    }
  }

  &.serif {
    header {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-family: $base--font-family;
      }
    }

    section {
      p {
        font-family: $base--font-family;
      }
    }
  }
}
