[role='button'] {
  &.btn-icon-simple {
    padding: 0.4rem 0.5rem;
    font-size: 0;
    line-height: 0;

    @media screen and (min-width: $mq-m) {
      padding: 0.6rem 1rem;
    }

    .label {
      .icon {
        svg {
          width: 2.2rem;
          height: 2.2rem;
        }
      }
    }
  }
}
