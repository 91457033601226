
label.selector {
  width: 100%;

  ul[role='list'] {
    display: none;
  }

  @media screen and (min-width: $mq-l) {
    ul[role='list'] {
      display: block;
      list-style: none;
      padding: 0;
      margin: 0;
      position: absolute;
      z-index: 1000;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &::before {
        content: '';
        display: block;
        height: 100%;
      }

      & > li {
        margin: 0;
        padding: 0;
        position: relative;

        ul {
          position: absolute;
          top: 0.1rem;
          display: block;
          flex-direction: column;
          width: 100%;
          height: auto;
          overflow: hidden;
          transition: all 200ms ease-in-out;
          background: $color__white;
          min-width: 5rem;
          list-style: none;
          padding: 0;
          margin: 0;
          opacity: 0;

          li {
            min-width: 4rem;
            background: $color__white;
            height: 100%;
            max-height: 0;
            transition: all 100ms ease-in-out;
            margin: 0;
            position: relative;

            & > a {
              display: block;
              padding: 1.2rem 1.6rem;

              span {
                justify-content: flex-start;

                strong {
                  line-height: 1.4em;
                  opacity: 1;
                }
              }

              &::after {
                max-height: 0;
              }
            }

            &:first-child {
              padding-top: 0.4rem;
            }

            &:last-child {
              padding-bottom: 0.4rem;
            }

            &:hover {
              & > a {
                background-color: $color__black--10;
                color: $color__black;
                opacity: 1;

                strong {
                  color: inherit;
                }
              }
            }

            &::before {
              content: '';
              position: absolute;
              z-index: -1;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              box-shadow: 0 0 0.4rem 0 rgba($color__black, 0.4);
            }
          }
        }
      }
    }

    &.is-active {
      ul {
        & > li {
          & > ul {
            z-index: 2000;
            overflow: visible;
            max-height: 100%;
            opacity: 1;

            &::before {
              content: '';
              position: absolute;
              top: -0.1rem;
              right: 0;
              left: 0;
              width: 100%;
            }

            li {
              max-height: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}
