$video__ratio: (720/1280)*100%;

.video {
  position: relative;
  padding-bottom: $video__ratio;
  padding-top: 2.5rem;
  height: 0;
  margin-bottom: 2rem;

  video {
    width: 100%;
    height: auto;
  }

  .video__controls {
    position: absolute;
    margin: auto;
    top: 48%;
    left: 48%;
    width: 3rem;
    height: 3rem;
    margin-top: -1.5rem;
    z-index: 2;
    background-color: transparent;
    border-color: transparent;
    color: $color__white;

    i {
      font-size: 3rem;
    }

    a {
      &::before {
        display: none;
      }
    }
  }

  .hidden {
    visibility: hidden;
  }

  .playing i {
    color: transparent;

    &:hover {
      color: inherit;
    }
  }
}

@media screen and (min-width: $mq-s) {
  .video {
    .video__control {
      width: 6rem;
      height: 6rem;
      margin-top: -3rem;

      i {
        font-size: 6rem;
      }
    }
  }
}

@media screen and (min-width: $mq-m) {
  .video {
    .video__control {
      width: 10rem;
      height: 10rem;
      margin-top: -5rem;

      i {
        font-size: 10rem;
      }
    }
  }
}
