.pageheader {
    // margin-top: 6rem;
    padding-bottom: 3rem;
    position: relative;

    &.article {
        padding-bottom: 0;
    }

    .pageheader-wrapper {
        .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // Polyfill-Notation for object-fit-images (IE11)
            font-family: 'object-fit: cover;';
        }

        .lead-text-wrapper {
            position: absolute;
            z-index: 1999;
            transform: translate(0, -50%);
            left: -7%;
            top: 70%;
            
            h1 {
                display: table-caption;
                position: relative;
                font-weight: $base--font-weight-bold;
                text-transform: uppercase;
                color: white;
                line-height: 0.9;
                font-size: 65px;
            }
        }
    }
}