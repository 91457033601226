.img-wrapper {
  position: relative;
  overflow: hidden;

  .img {
      object-fit: cover;
      font-family: 'object-fit: cover;';
      position: absolute;
      width: 100%;
      height: 100%;
  }
}

.image-caption {
    font-size: 12px;
    padding-top: 8px;
    font-family: $base--font-family;
    color: #666666;
    opacity: 1;
}
// ******
// Global styling for img-wrapper and img to handle .short & .tall for various column sizes
// ******

// 1/3
.col-m-4 {
  .short {
      .img-wrapper, .iframe-wrapper {
        padding-bottom: 75.7%;
      }
  }

  .tall {
    .img-wrapper, .iframe-wrapper {
        padding-bottom: 136.25%;
      }
  }
  
  .sixteen-nine {
    .img-wrapper, .iframe-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
    }
  }
}

// 2/3
.col-m-8 {
    .short {
        .img-wrapper, .iframe-wrapper {
            padding-bottom: calc(37.59% - (0.25 * #{$grid-gap}));
        }
    }    

    .tall {
        .img-wrapper, .iframe-wrapper {
            padding-bottom: calc(67.76% - (0.5 * #{$grid-gap}));
        }
    }

    .sixteen-nine {
        .img-wrapper, .iframe-wrapper {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 25px;
            height: 0;
        }
      }
}

// 3/3
.col-m-12 {
    .short {
        .img-wrapper, .iframe-wrapper {
            padding-bottom: calc(25.22% - (0.5 * #{$grid-gap}));
        }
    }    

    .tall {
        .img-wrapper, .iframe-wrapper {
            // Solving for height = 495px @ 1120px (full width screen)
            padding-bottom: calc(44.87% - (0.5 * #{$grid-gap}));
        }
    }

    .sixteen-nine {
        .img-wrapper, .iframe-wrapper {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            padding-top: 25px;
            height: 0;
        }
      }
}