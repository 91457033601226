$tab__space: 1rem;

.tab__nav {
  @include clearfix;
  display: block;
  list-style: none;
  padding: 0 1rem 1rem;
  margin: 1rem 0 2rem;
  background-color: $color__black--10;
}

.tab__nav-item {
  display: block;
  float: left;
  margin: 1rem 1rem 0 0;

  &:last-child {
    margin-right: 0;
  }

  a {
    display: block;
    background-color: $color__black--5;
    color: $color__black--20;
    padding: 1rem;
  }

  &--active {
    a {
      background-color: $color__white;
      color: $color__primary--default;
    }
  }

  a:hover {
    color: $color__black;
  }
}

.tab__panel {
  display: none;
}

// STATES
.tab__panel--active {
  display: block;
}

@media screen and (min-width: $mq-m) {
  .tab__nav {
    margin-top: 2rem;
    padding: 0 2rem;
  }

  .tab__nav-item {
    margin-top: 2rem;
    margin-right: 1rem;
    font-size: 1.8rem;
  }
}
