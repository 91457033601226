[role='button'] {
  &.btn-text {
    min-width: 12rem;
    //margin: 0.6rem; //This is needed for focus state?
    padding: 0.6rem 3.2rem;
    font-size: 1.3rem;
    line-height: 2rem;
    transition: padding-right 300ms ease-in-out, padding-left 300ms ease-in-out;

    @media screen and (min-width: $mq-s) {
      min-width: 15rem;
      padding-top: 0.9rem;
      padding-bottom: 0.9rem;
      font-size: 1.5rem;
      line-height: 2.2rem;
    }

    @media screen and (min-width: $mq-m) {
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
    }

    @media screen and (min-width: $mq-l) {
      min-width: 18.3rem;
      padding-top: 1.7rem;
      padding-bottom: 1.7rem;
      font-size: 1.7rem;
      line-height: 2.4rem;
    }

    &.btn-icon {
      &:not(.icon-stay-visible) {
        &:not(:disabled) {
          &:hover {
            padding-right: 2.1rem;
            padding-left: 2.4rem;

            @media screen and (min-width: $mq-s) {
              padding-right: 2rem;
              padding-left: 2.1rem;
            }

            @media screen and (min-width: $mq-l) {
              padding-right: 1.4rem;
              padding-left: 1.7rem;
            }

            .label {
              &[icon-pos='before'],
              &[icon-pos='after'] {
                .icon {
                  width: auto;
                  opacity: 1;

                  svg {
                    width: 1.5rem;
                  }
                }
              }

              &[icon-pos='before'] {
                strong {
                  padding-left: 0.4rem;

                  @media screen and (min-width: $mq-s) {
                    padding-left: 0.8rem;
                  }

                  @media screen and (min-width: $mq-l) {
                    padding-left: 1.8rem;
                  }
                }
              }

              &[icon-pos='after'] {
                strong {
                  padding-right: 0.4rem;

                  @media screen and (min-width: $mq-s) {
                    padding-right: 0.8rem;
                  }

                  @media screen and (min-width: $mq-l) {
                    padding-right: 1.8rem;
                  }
                }
              }
            }
          }
        }
      }

      &:not(.icon-stay-visible) {
        .label {
          &[icon-pos='before'],
          &[icon-pos='after'] {
            .icon {
              width: 0;
              padding: 0;
              opacity: 0;
              transition-property: width, opacity;
              transition-duration: 300ms;
              transition-timing-function: ease-in-out;

              svg {
                width: 0;
                transition: width 300ms ease-in-out;
              }
            }

            strong {
              padding-right: 0;
              padding-left: 0;
              transition: padding-right 300ms ease-in-out, padding-left 300ms ease-in-out;
            }
          }
        }
      }
    }

    .label {
      display: table;
      margin: 0 auto;
      font-size: inherit;
      line-height: inherit;

      .icon {
        display: table-cell;
        overflow: hidden;
      }

      strong {
        display: table-cell;
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
}
