// Page MaxWidth
$page-maxWidth: 135rem;

// Row MaxWidth
// $row-maxWidth: 96rem;
$container-padding: 1.2rem; // Default 12px left/right
$container-padding-s: 3.2rem; // Small 32px left/right
$container-padding-m: 3.2rem; // Small 32px left/right
$container-padding-l: 11.5rem; // Large 115px left/right

// GRID
$mq-s: 500px; // Use columns at this or higher (stack at this or lower)
$mq-m: 800px;
$mq-l: 1200px; // Pad article content at this or higher

// COLORS (START) 
// Auto-Generated section, please edit in src/atoms/colors/colors.yml 
// Use SASS function rgba, darken, lighten ... to adjust color to your needs. 
// For more Info see: https://robots.thoughtbot.com/controlling-color-with-sass-color-functions
//--- BLACK ---//
$color--black: rgb(0, 0, 0);

//--- BLACK-- ---//
$color__black--5: rgb(250, 250, 250);

$color__black--10: rgb(229, 229, 229);

$color__black--20: rgb(204, 204, 204);

$color__black--40: rgb(153, 153, 153);

$color__black--60: rgb(102, 102, 102);

$color__black--80: rgb(51, 51, 51);

//--- GRAY-- ---//
$color__gray--light: rgb(238, 238, 238);

$color__gray--dark: rgb(99, 102, 107);

//--- WHITE ---//
$color--white: rgb(255, 255, 255);

//--- LINK-- ---//
$color__link--default: rgb(0, 0, 0);

$color__link--hover: rgb(0, 0, 0);

$color__link--active: rgb(0, 0, 0);

//--- BUTTON-- ---//
$color__button--default: rgb(0, 0, 0);

$color__button--hover: rgb(0, 0, 0);

$color__button--active: rgb(0, 0, 0);

//--- ALERT-- ---//
$color__alert--error: rgb(244, 103, 53);

$color__alert--warning: rgb(255, 188, 96);

$color__alert--success: rgb(94, 255, 91);

$color__alert--info: rgb(137, 255, 255);

//--- PRIMARY-- ---//
$color__primary--default: rgb(204, 204, 204);

//--- SECONDARY-- ---//
$color__secondary--default: rgb(229, 229, 229);

//--- HIERONYMUS-- ---//
$color__hieronymus--default: rgb(158, 123, 61);

// COLORS (END)

// TYPOGRAPHY
// ----------------------------------------
$base--font-family: 'europa', 'sans-serif';
$base--font-size: 10px;

$base--font-family-secondary: 'miller-display', 'serif';
// For mor einformations see: https://discourse.wicg.io/t/more-font-weight-names/892
$base--font-weight-thin: 100;
$base--font-weight-extralight: 200;
$base--font-weight-light: 300;
$base--font-weight-regular: 400;
$base--font-weight-medium: 500;
$base--font-weight-semibold: 600;
$base--font-weight-bold: 700;
$base--font-weight-extrabold: 800;
$base--font-weight-black: 900;

$component__bottom-margin: 60px;