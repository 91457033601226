.articleheader {

    .teaser-img-wrapper {
        position: relative;
        overflow: hidden;
        padding-bottom: calc(75% - (0.5 * #{$grid-gap}));

        .teaser-img {
            object-fit: cover;
            // Polyfill-Notation for object-fit-images (IE11)
            font-family: 'object-fit: cover;';
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    .article-title h1 {
        margin-top: 50px;
        text-transform: uppercase;
        display: table-caption;
        font-weight: $base--font-weight-bold;
        line-height: 0.9;
        font-size: 35px;

        @media screen and (min-width: #{$mq-m}) {
            font-size: 62px;
        }
    }

    .article-category h3 {
        text-transform: capitalize;
        font-size: 30px;
        font-weight: 200;
        font-family: $base--font-family-secondary;
        font-style: italic;
    }

    .intro-title-and-author-container {
        padding-top: 15px;
        border-top: 1px solid black;
        
        .introduction-title {
            text-transform: uppercase;
            font-size: 30px;
        }

        .introduction-author {
            font-family: $base--font-family-secondary;
            font-size: 25px;
            font-style: italic;
        }
    }
}